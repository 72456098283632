import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import "swiper/css/bundle"
import client from "./apollo-client"
import { ApolloProvider } from "@apollo/client"
import { PrimeReactProvider } from "primereact/api"
import "primereact/resources/themes/lara-light-cyan/theme.css"
// import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import * as serviceWorker from "./serviceWorker"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </ApolloProvider>
  </React.StrictMode>
)
