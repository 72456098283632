import "../../css/style.css"
import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import "./adminDashboard.css"
import { useEffect } from "react"

import { useNavigate } from "react-router-dom"
import { Paths } from "../../../utils/Paths"

const AdminDashBoard = () => {
  const navigate = useNavigate()

  return (
    <div className={`${styles.container} main_tone`}>
      <Profile back_url={Paths.admin} logoLink={Paths.esignDashboard} />
      <div className="input_container">
        <br />
        <h2>管理員介面</h2>
        <div className="admin-control-row">
          <h2>註冊客人戶口</h2>
          <Button
            variant="contained"
            className={styles.list_button}
            onClick={() => {
              navigate(Paths.esignSignUp)
            }}
          >
            註冊
          </Button>
        </div>
        <hr className={styles.divider}></hr>
        <div className="admin-control-row">
          <h2>客戶資料</h2>
          <Button
            variant="contained"
            className={styles.list_button}
            onClick={() => {
              navigate(Paths.esignClientList)
            }}
          >
            進入
          </Button>
        </div>
        <hr className={styles.divider}></hr>
      </div>
    </div>
  )
}

export default AdminDashBoard
