import { useEffect, useState } from "react"
import { styled, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import ListSubheader from "@mui/material/ListSubheader"
import CssBaseline from "@mui/material/CssBaseline"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

import EditView from "../EditView/EditView"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { EditListNames } from "../utls/EditListNames"
import { loggingOut } from "../utls/loggingOut"
import { Paths } from "../../../utils/Paths"

//get from local storage

const drawerWidth = 240
const topMenuStyle = {
  background: "#47cfd7",
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(10, 3, 0, 3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default function Dashboard() {
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const [userName, setUserName] = useState("")
  const [editListName, setEditListName] = useState(
    EditListNames.APPLICATION_FORMS
  )
  const [viewName, setViewName] = useState("")
  const navigate = useNavigate()

  const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"))

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin")
    if (isAdmin === "true") {
      setUserName("- Admin")
    } else {
      setUserName("")
    }
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const userlist =
    isSuperAdmin === true
      ? [
          {
            text: "Users",
            listName: EditListNames.USERS,
          },
          { text: "Meta", listName: EditListNames.METAS },
          { text: "Logout", listName: "" },
        ]
      : [
          {
            text: "Users",
            listName: EditListNames.USERS,
          },
          { text: "Meta", listName: EditListNames.METAS },
          { text: "Logout", listName: "" },
        ]

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={topMenuStyle}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard {userName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate(Paths.admin)
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary={"Back"} />
          </ListItemButton>
        </ListItem>

        <Divider />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-content-block"
          subheader={
            <ListSubheader component="div" id="nested-list-content-block">
              Submissions
            </ListSubheader>
          }
        >
          {[
            {
              text: "Application Forms",
              listName: EditListNames.APPLICATION_FORMS,
            },
            {
              text: "Application Form Registers",
              listName: EditListNames.APPLICATION_FORM_REGISTERS,
            },
          ].map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  setEditListName(item.listName)
                }}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <EmailOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-content-block"
          subheader={
            <ListSubheader component="div" id="nested-list-content-block">
              Content Block
            </ListSubheader>
          }
        >
          {[
            // { text: "Menu", listName: EditListNames.MENUS },
      
          ].map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  setEditListName(item.listName)
                }}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <ViewQuiltOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider /> */}
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-pages"
          subheader={
            <ListSubheader component="div" id="nested-list-pages">
              Pages
            </ListSubheader>
          }
        >
          {[
            { text: "Post", listName: EditListNames.POSTS },
            { text: "Home", listName: EditListNames.HOMES },
            { text: "About", listName: EditListNames.ABOUTS },
            { text: "Large Amount", listName: EditListNames.LARGE_AMOUNTS },
            { text: "Personal Loan", listName: EditListNames.PERSONAL_LOANS },
            { text: "Mortgage Loan", listName: EditListNames.MORTGAGES },
            { text: "QA", listName: EditListNames.QAS },
          ].map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  setEditListName(item.listName)
                }}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <ArticleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-pages"
          subheader={
            <ListSubheader component="div" id="nested-list-pages">
              Configuration
            </ListSubheader>
          }
        >
          {userlist.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  if (item.text === "Logout") {
                    loggingOut()
                    navigate(Paths.adminLogin)
                  } else {
                    setEditListName(item.listName)
                  }
                }}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  {item.listName === EditListNames.USERS ? (
                    <AccountCircleOutlinedIcon />
                  ) : item.listName === EditListNames.METAS ? (
                    <SettingsOutlinedIcon />
                  ) : (
                    <LogoutOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <EditView key={editListName} editListName={editListName} />
      </Main>
    </Box>
  )
}
