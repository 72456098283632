import { gql } from "@apollo/client"

export const GET_LARGE_AMOUNTS = gql`
  query LargeAmounts {
    largeAmounts {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_LARGE_AMOUNT = gql`
  mutation UpdateLargeAmount(
    $where: LargeAmountWhereUniqueInput!
    $data: LargeAmountUpdateInput!
  ) {
    updateLargeAmount(where: $where, data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const CREATE_LARGE_AMOUNT = gql`
  mutation CreateLargeAmount($data: LargeAmountCreateInput!) {
    createLargeAmount(data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_LARGE_AMOUNT = gql`
  mutation DeleteLargeAmount($where: LargeAmountWhereUniqueInput!) {
    deleteLargeAmount(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_LARGE_AMOUNTS = gql`
  mutation DeleteLargeAmounts($where: [LargeAmountWhereUniqueInput!]!) {
    deleteLargeAmounts(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
