/* global dataLayer */
import css from "./css/thankyou.module.css"

import { useEffect } from "react"

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className={`${css.content}`}>
      <h4>歡迎閣下申請 Credit Pro 貸款</h4>
      <h2>恭喜閣下已成功遞交申請，我們會盡快通知您！</h2>
    </div>
  )
}

export default Thankyou
