import { useEffect, useState } from "react"
import { styled, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import ListSubheader from "@mui/material/ListSubheader"
import CssBaseline from "@mui/material/CssBaseline"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import { useNavigate } from "react-router-dom"
import { loggingOut } from "./utls/loggingOut"
import { Card } from "primereact/card"
import cms_icon from "../../images/backend/cms-icon.png"
import esign_icon from "../../images/backend/e-signature-icon.png"
import styles from "./adminServices.module.css"
import { Paths } from "../../utils/Paths"
import { useMediaQuery } from "react-responsive"

const drawerWidth = 240
const topMenuStyle = {
  background: "#47cfd7",
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(10, 3, 0, 3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default function AdminServices() {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })

  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [userName, setUserName] = useState("")
  const [showCMS, setShowCMS] = useState(false)
  const [showEsignature, setShowEsignature] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin")
    const showCMS = localStorage.getItem("showCMS")
    const showEsignature = localStorage.getItem("showEsignature")

    if (showCMS === "true") {
      setShowCMS(true)
    } else {
      setShowCMS(false)
    }
    if (showEsignature === "true") {
      setShowEsignature(true)
    } else {
      setShowEsignature(false)
    }

    if (isAdmin === "true") {
      setUserName("Admin")
    } else {
      setUserName("")
    }
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={topMenuStyle}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {userName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-pages"
          subheader={
            <ListSubheader component="div" id="nested-list-pages">
              Configuration
            </ListSubheader>
          }
        >
          {[{ text: "Logout", listName: "" }].map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  if (item.text === "Logout") {
                    loggingOut()
                    navigate(Paths.adminLogin)
                  }
                }}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        {!isMobile && <div className="space"></div>}
        <div className={styles.container}>
          {showCMS && (
            <div
              className={`card ${styles.card_container}`}
              onClick={() => navigate(Paths.cmsDashboard)}
            >
              <Card className={styles.card_wrapper}>
                <h2>CMS</h2>
                <img src={cms_icon} />
              </Card>
            </div>
          )}
          {showEsignature && (
            <div
              className={`card ${styles.card_container}`}
              onClick={() => navigate(Paths.esignDashboard)}
            >
              <Card className={styles.card_wrapper}>
                <h2>E-signature</h2>
                <img src={esign_icon} />
              </Card>
            </div>
          )}
          {/* <div
            className={`card ${styles.card_container}`}
            onClick={() => navigate("/admin/updateuser")}
          >
            <Card className={styles.card_wrapper}>
              <h2>uploadfile</h2>
              <img src={esign_icon} />
            </Card>
          </div> */}
        </div>
        <div className="space"></div>
      </Main>
    </Box>
  )
}
