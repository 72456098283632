import React, { useEffect, useState } from "react"
import { Box, Button, TextField, Typography, MenuItem } from "@mui/material"
import styles from "./css/applyLogin.module.css"
import ApplicationSection from "../compoients/ApplicationSection/ApplicationSection"

import bgfont from "../images/applyForm/bg-fonts.png"
import LoginSection from "../compoients/LoginSection/LoginSection"
import { useMediaQuery } from "react-responsive"
import useDeviceQueries from "../hooks/useDeviceQueries"
const ApplyLogin = () => {
  const { isDesktop, isTablet, isMobile } = useDeviceQueries()

  const [isLoginTab, setLoginTab] = useState(false)
  const [step, setStep] = useState(1)

  const handleTabClick = (isLogin) => {
    setLoginTab(isLogin)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
            backgroundColor: "#ffffff99",
            borderRadius: "12px",
          },
          //InputAdornment text change color
          "& .MuiTypography-root": {
            color: "unset",
          },
          //textfield input text color
          "& input": {
            color: "#575757",
            zIndex: 99,
            fontSize: "18px",
          },
          //mutiple select selected text color
          "& .MuiSelect-select": {
            color: "#575757",
            fontSize: "18px",
            zIndex: 99,
          },
        },
      }}
      className={styles.container}
    >
      {!isMobile && (
        <Box className={styles.leftSection}>
          {/* <img src={bgfont} alt="bg-font" /> */}
        </Box>
      )}
      <div className={styles.formSection}>
        {step === 1 && (
          <div className="d-flex mt-5">
            <div
              className={`${styles.applyTab}`}
              // style={{ color: isLoginTab ? "#989898" : "#108d91" }}
              style={{ color: isLoginTab ? "white" : "white" }}
              onClick={() => handleTabClick(false)}
            >
              申請
            </div>
            {/* <div
              className={`${styles.loginTab} ms-5`}
              style={{ color: isLoginTab ? "#108d91" : "#989898" }}
              onClick={() => handleTabClick(true)}
            >
              登入
            </div> */}
          </div>
        )}
        {isLoginTab ? (
          <LoginSection />
        ) : (
          <ApplicationSection onStepChange={setStep} />
        )}
      </div>
    </Box>
  )
}

export default ApplyLogin
