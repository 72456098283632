import css from "./css/imageWithText.module.css"

const ImageWithText = () => {
  return (
    <div className={css.container}>
      <ul className={css.textOverlay}>
        <li>免TU 過往信貸不影響申請</li>
        <li>24/7即時過數</li>
        <li>特快網上貸款體驗</li>
      </ul>
    </div>
  )
}

export default ImageWithText
