import css from "./css/quoteSection.module.css"
import quote_img from "../images/icon/quote.png"

const QuoteSection = () => {
  const title = "CREDIT PRO FINANCE LIMITED"
  const content =
    "無論小額借貸還是大額借貸，我們均能提供極速免TU網上貸款服務，盡低息費率、盡快批核過程。當私人信貸額，只需提交所需借簽文件，不論申請貸款的理由，我們都能提供簡易靈活的貸款服務，滿足你的周轉需要！"
  const checklist = [
    "申請不限TU，任何信貸評級一樣幫到你",
    "整個貸款申請過程可於網上完成，過程更流暢簡單",
    "因應客戶不同評級去設定不同貸款計劃，較低的利息或較長的還款期，助你還款變得彈性 靈活減輕債務負擔。",
  ]
  return (
    <div className={css.container}>
      <div className={css.item_wrapper}>
        <div className={css.item}>
          <img src={quote_img} alt="quote" className={css.quote_img} />
          <h3 className="pb-2">{title}</h3>
          <p>{content}</p>
          <ul className={css.checklist}>
            {checklist.map((item, index) => (
              <li key={index}>
                <span className={css.checkbox}></span>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default QuoteSection
