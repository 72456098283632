import React, { useEffect, useState } from "react"
import css from "./css/reactAccordion.module.css"
import { Accordion, AccordionTab } from "primereact/accordion"
import { wrapText } from "../utils/wrapText"
import { useMediaQuery } from "react-responsive"

import { useQuery } from "@apollo/client"
import { GET_QAS } from "../graphql/QA/QA_GQL"

const RecatAccordion = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 801px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 500px)" })
  const [expanded, setExpanded] = useState(false)

  const { loading, data } = useQuery(GET_QAS)
  const qas = data?.qAS
  // const qas = [
  //   {
  //     question: "Creditpro 貸款批核流程如何運作？",
  //     answer: "Creditpro 貸款批核流程如何運作？",
  //     order: 1,
  //   },
  //   {
  //     question: "想申請貸款，必須符合甚麼資格？",
  //     answer: "您必須年滿18歲，並擁有香港居留權之居民，自僱或受僱人士均可。",
  //     order: 2,
  //   },
  //   {
  //     question:
  //       "當申請貸款時，CPF會否向信貸資料庫查閱申請人於銀行及其他財務機構之個人信貸資料？",
  //     answer:
  //       "當申請貸款時，CPF會否向信貸資料庫查閱申請人於銀行及其他財務機構之個人信貸資料？",
  //     order: 3,
  //   },
  // ]

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const title_style = isDesktop
    ? {
        color: "#212529",
        fontSize: "20px",
        fontWeight: "600",
      }
    : isMobile_small
    ? {
        color: "#212529",
        fontSize: "12px",
        fontWeight: "600",
      }
    : { color: "#212529", fontSize: "16px", fontWeight: "600" }

  const answer_style = isDesktop
    ? {
        color: "#8cc4df",
        fontSize: "1rem",
        fontWeight: "500",
        textAlign: "left",
        paddingLeft: "35px",
      }
    : isMobile_small
    ? {
        color: "#8cc4df",
        fontSize: "12px",
        fontWeight: "500",
        textAlign: "left",
        paddingLeft: "35px",
      }
    : {
        color: "#8cc4df",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: "left",
        paddingLeft: "35px",
      }

  return (
    <div className={`${css.container}`}>
      {loading && <p>Loading...</p>}
      <Accordion
        multiple
        activeIndex={0}
        className={css.accordion_container}
        expandIcon={
          <i
            className="pi pi-sort-down-fill"
            style={{
              fontSize: "1rem",
              marginRight: "20px",
              transform: "rotate(-90deg) scaleY(1.8)",
            }}
          ></i>
        }
        collapseIcon={
          <i
            className="pi pi-sort-down-fill"
            style={{
              fontSize: "1rem",
              marginRight: "20px",
              transform: "scaleY(1.8)",
            }}
          ></i>
        }
      >
        {qas?.map((item, index) => (
          <AccordionTab
            key={index}
            header={item.question}
            className={css.answer}
          >
            <div style={answer_style}>{wrapText(item.answer)}</div>
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  )
}

export default RecatAccordion
