import { Link } from "react-router-dom"
import Button from "@mui/material/Button"
import "./profile.css"
import Header from "../Header/Header"
import styles from "../../css/UI.module.css"
import backbtn from "../../image/button/pre_btn.png"

import { useNavigate } from "react-router"
import { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../AuthContext"
import { useLocation } from "react-router-dom"
import { Paths } from "../../../utils/Paths"

const Profile = ({ back_url, logoLink, currentpage, setLogout }) => {
  const location = useLocation()
  const { state, dispatch } = useContext(AuthContext)

  const blockLocation = ["/admin"]

  let navigate = useNavigate()

  const handleLogout = (e) => {
    e.preventDefault()
    localStorage.setItem("isLogin", false)
    localStorage.removeItem("user")
    localStorage.removeItem("isAdmin")
  }

  const [isLogin, setIsLogin] = useState(false)
  useEffect(() => {
    const login = localStorage.getItem("isLogin")
    login ? setIsLogin(true) : setIsLogin(false)
  }, [isLogin])

  const isPathBlocked = (pathname) => {
    return blockLocation.some((blockedPath) => pathname.startsWith(blockedPath))
  }
  return (
    <div className="profile-container">
      {/* <Link to={logoLink} className="profileLink">
        <Header />
      </Link> */}
      {isLogin ? (
        <div className="profile">
          {back_url !== "" ? (
            <img
              src={backbtn}
              className="back_button"
              onClick={() => {
                navigate(back_url)
              }}
              alt="back"
            />
          ) : (
            <div></div>
          )}

          {currentpage !== "login" && (
            <Button
              variant="contained"
              className={styles.logout_button}
              onClick={(e) => {
                handleLogout(e)
                dispatch({
                  type: "LOGOUT",
                })
                localStorage.setItem("isLogin", false)

                if (isPathBlocked(location.pathname)) {
                  navigate(Paths.adminLogin)
                } else {
                  navigate(Paths.userLogin)
                }
              }}
            >
              登出
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default Profile
