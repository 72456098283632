// ErrorBoundary.js
import React, { Component } from "react"
import { Navigate } from "react-router-dom"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // 更新 state 以觸發下一個 render 顯示降級 UI
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // 你也可以將錯誤記錄到一個錯誤報告服務
    console.error("ErrorBoundary caught an error", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // 你可以 render 任何自定義的錯誤 UI
      return <Navigate to="/service-error" />
    }

    return this.props.children
  }
}

export default ErrorBoundary
