import Calculator from "./Calculator"
import css from "./css/calculatorSection.module.css"
import bg from "../images/home/part2-bg.png"
import bg_mob from "../images/home/calculator-bg-mob.png"
import { useMediaQuery } from "react-responsive"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import useDeviceQueries from "../hooks/useDeviceQueries"
import { useEffect, useRef } from "react"

gsap.registerPlugin(ScrollTrigger)

const CalculatorSection = () => {
  const { isDesktop, isTablet, isMobile, isLaptop } = useDeviceQueries()
  const calculatorRef = useRef(null)
  const bgRef = useRef(null)
  const titleRef = useRef(null)
  const titleChiRef = useRef(null)

  useEffect(() => {
    if (isDesktop || isLaptop) {
      // Setup GSAP animation for scroll-triggered fade-in effect
      gsap.set(calculatorRef.current, { y: 50, opacity: 0 })
      gsap.set(bgRef.current, { opacity: 0 })
      gsap.set(titleRef.current, { y: -100, opacity: 0 })
      gsap.set(titleChiRef.current, { x: -100, opacity: 0 })
      gsap.to([calculatorRef.current, bgRef.current], {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: calculatorRef.current,
          start: "top 80%", // Adjust the position to trigger the animation
          end: "top 50%", // Optional: Define the end position
          scrub: false, // Disable scrub for a simple fade-in
          once: true, // Only trigger the animation once
        },
      })

      gsap.to([titleRef.current, titleChiRef.current], {
        y: 0,
        x: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: calculatorRef.current,
          start: "top 80%", // Adjust the position to trigger the animation
          end: "top 50%", // Optional: Define the end position
          scrub: false, // Disable scrub for a simple fade-in
          once: true, // Only trigger the animation once
        },
      })
    }
    if (isMobile) {
      gsap.set(titleChiRef.current, { x: 100, opacity: 0 })
      gsap.set(calculatorRef.current, { y: 50, opacity: 0 })
      gsap.to([calculatorRef.current, titleChiRef.current], {
        y: 0,
        x: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: titleChiRef.current,
          start: "top 80%", // Adjust the position to trigger the animation
          end: "top 50%", // Optional: Define the end position
          scrub: false, // Disable scrub for a simple fade-in
          once: true, // Only trigger the animation once
        },
      })
    }
  }, [isDesktop, isTablet, isMobile, isLaptop])

  //desktop
  if (isDesktop || isLaptop) {
    return (
      <div className={css.container}>
        <div className={css.inner_container}>
          <div ref={bgRef} className={css.bg_container}>
            {/* <img src={bg} alt="bg" /> */}
          </div>
          <div ref={calculatorRef} className={css.calculator_wrapper}>
            <Calculator />
          </div>
          <div ref={titleRef} className={css.title}>
            Calculator
          </div>
          <div ref={titleChiRef} className={css.title_chi}>
            貸款計數機
          </div>
        </div>
      </div>
    )
  } else if (isTablet || isMobile) {
    //mobile
    return (
      <div className={css.container_mob}>
        <div ref={titleChiRef} className={css.title_chi_mobile}>
          <span className="color4">貸款</span>
          <span className="color5">計數機</span>
        </div>
        <img src={bg_mob} alt="bg" className={css.cal_container_bg} />
        <div ref={calculatorRef} className={css.cal_container_mobile}>
          {/* <div className={css.bg_container_mobile}></div> */}
          <Calculator />
        </div>
      </div>
    )
  }
}

export default CalculatorSection
