import { gql } from "@apollo/client"

export const GET_PROPERTY_LOANS = gql`
  query PropertyLoans {
    propertyLoans {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_PROPERTY_LOAN = gql`
  mutation UpdatePropertyLoan(
    $where: PropertyLoanWhereUniqueInput!
    $data: PropertyLoanUpdateInput!
  ) {
    updatePropertyLoan(where: $where, data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const CREATE_PROPERTY_LOAN = gql`
  mutation CreatePropertyLoan($data: PropertyLoanCreateInput!) {
    createPropertyLoan(data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_PROPERTY_LOAN = gql`
  mutation DeletePropertyLoan($where: PropertyLoanWhereUniqueInput!) {
    deletePropertyLoan(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_PROPERTY_LOANS = gql`
  mutation DeletePropertyLoans($where: [PropertyLoanWhereUniqueInput!]!) {
    deletePropertyLoans(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
