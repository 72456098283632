import { Alert, Box } from "@mui/material"
import { useNavigate } from "react-router"
import "../../signature/css/login.css"
import styles from "./loginSection.module.css"
import "../../signature/css/style.css"
import { useEffect, useState, useContext } from "react"
import { getUserDataFromPhone } from "../../signature/firebaseConf"
import Profile from "../../signature/components/Profile/Profile"
import moment from "moment"
import { AuthContext } from "../../AuthContext"
import PhoneSMSVerification from "../PhoneSMSVerification/PhoneSMSVerification"
import { Paths } from "../../utils/Paths"
import { userRole } from "../../utils/UserRole"
import Cookies from "js-cookie"
import { checkUserVaild } from "../../utils/checkUserVaild"

const LoginSection = () => {
  const { state, dispatch } = useContext(AuthContext)
  const [isSmsVerified, setIsVerified] = useState(false)
  const [formState, setFormState] = useState({
    phoneNumb: "",
    error: "",
  })

  const { phoneNumb, error } = formState
  let navigate = useNavigate()

  const handleLogin = async () => {
    const user = { phoneNumb }
    const rs = await getUserDataFromPhone(user)

    if (rs.result) {
      // let userTimestamp = rs.userDetail.timestamp
      // userTimestamp = moment(userTimestamp, "MMMM Do YYYY, h:mm:ss a")

      // let now_date = moment()
      // let date_diff = now_date.diff(userTimestamp, "days")

      if (!checkUserVaild(rs.userDetail)) {
        setFormState((prevState) => ({
          ...prevState,
          error: "對不起，戶口已過期",
        }))
        return
      }

      if (
        rs.userDetail.docId === "superadmin" ||
        rs.userDetail.docId === "sales"
      ) {
        dispatch({
          type: "LOGIN",
          payload: {
            userRole: userRole.admin,
          },
        })
        localStorage.setItem("userRole", JSON.stringify(userRole.admin))
        localStorage.setItem("isLogin", true)

        // Set a cookie with a 10-hour expiration
        Cookies.set("keystonejs-session", "true", { expires: 10 / 24 })
        navigate(Paths.admin)
      } else {
        localStorage.setItem("userRole", JSON.stringify(userRole.user))
        localStorage.setItem("isLogin", true)
        localStorage.setItem("userID", rs.userDetail.docId)

        dispatch({
          type: "LOGIN",
          payload: {
            userRole: userRole.user,
          },
        })
        navigate(Paths.userDashboard + rs.userDetail.docId)
      }
    }

    if (rs.error !== "")
      setFormState((prevState) => ({ ...prevState, error: rs.error }))
  }

  const handlePhoneChange = (input) => {
    setFormState((prevState) => ({
      ...prevState,
      phoneNumb: input,
    }))
  }

  useEffect(() => {
    if (isSmsVerified) {
      handleLogin()
    }
  }, [isSmsVerified])

  return (
    <Box className={styles.boxSection}>
      <Profile back_url={""} logoLink="/login" currentpage={"login"} />
      <div className={styles.formContainer}>
        <PhoneSMSVerification
          onSuccess={setIsVerified}
          setPhone={handlePhoneChange}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </Box>
  )
}

export default LoginSection
