import React, { useState, useEffect } from "react"
import css from "./popupNotification.module.css" // Add custom styles here
import { findContentByLabel } from "../../utils/findContentByLabel"
import { wrapText } from "../../utils/wrapText"
import { useNavigate } from "react-router-dom"
import { Paths } from "../../utils/Paths"

const PopupNotification = ({ onButtonClick, data }) => {
  const [isVisible, setIsVisible] = useState(false)
  const message =
    findContentByLabel(data, "popup_notification") ||
    "重要通知：提高防騙徒冒認本公司代表/員工"
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })

    onButtonClick(false)
    const lastDismissed = localStorage.getItem("popupLastDismissed")
    const now = Date.now()
    // const oneDay = 24 * 60 * 60 * 1000
    // const thrityMins = 30 * 60 * 1000 // 30 mins
    setIsVisible(true)
    // if (!lastDismissed || now - lastDismissed > thrityMins) {
    //   setIsVisible(true)
    // }
  }, [])

  const handleClose = () => {
    onButtonClick(true)
    // Fade out effect by removing visible class after delay
    setIsVisible(false)

    setTimeout(() => {
      localStorage.setItem("popupLastDismissed", Date.now())
    }, 500) // Delay to match CSS transition duration
  }

  return (
    <div className={`${css.popupOverlay} ${isVisible ? css.visible : ""}`}>
      <div className={css.popupContent}>
        <button onClick={handleClose} className={css.closeButton}>
          ×
        </button>

        <div className={css.popupContent}>
          <div className={css.row}>
            <div className={css.icon}>⚠️</div>
            {wrapText(message)}
          </div>

          <button
            className={css.moreInfoButton}
            onClick={() => navigate(Paths.announcement)}
          >
            了解更多
          </button>
        </div>
      </div>
    </div>
  )
}

export default PopupNotification
