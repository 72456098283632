// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAAeY8S_gIC-VIRX0mWoekIJCyYK3TvHTA",
  authDomain: "credit-pro-9ab98.firebaseapp.com",
  projectId: "credit-pro-9ab98",
  storageBucket: "credit-pro-9ab98.firebasestorage.app",
  messagingSenderId: "989369514984",
  appId: "1:989369514984:web:4f65bc02603249d017825d",
  measurementId: "G-TH6LGVNREP",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export default app
// const analytics = getAnalytics(app)
