import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import Typography from "@mui/material/Typography"
import { useState } from "react"

const CalculatorSlider = ({
  minLabel,
  maxLabel,
  max_value,
  min_value,
  step_value,
  setChangedValue,
  inputChanged,
  sliderColor,
}) => {
  return (
    // <Box className="d-flex justify-content-center pt-2 pb-3">
    <Box width={"100%"}>
      <Slider
        defaultValue={0}
        aria-label="Default"
        valueLabelDisplay="auto"
        max={max_value}
        min={min_value}
        // sx={{
        //   color: sliderColor,
        //   height: "6px",
        //   "& .MuiSlider-thumb": {
        //     width: 12,
        //     height: 12,
        //     backgroundColor: "#2ccfd2",
        //     "&:before": {
        //       boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        //     },
        //   },
        //   "& .MuiSlider-rail": {
        //     color: "#a9a9a9",
        //   },
        // }}
        sx={{
          height: "5px",
          color: "white",
          "& .MuiSlider-track": {
            background: "white",
            boxShadow: "0px 0px 4px 3px #00eaff", // Add a glow effect
          },

          "& .MuiSlider-thumb": {
            width: 16,
            height: 16,
            backgroundColor: "white",
            boxShadow: "0px 0px 4px 3px #00eaff", // Glowing effect around the thumb
            "&:before": {
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            },
          },
          "& .MuiSlider-rail": {
            backgroundColor: "white",
            opacity: 0.8,
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: "#00eaff", // Make sure the value label matches the glow color
          },
        }}
        step={step_value}
        value={parseInt(inputChanged)}
        onChange={(e) => {
          setChangedValue(e.target.value)
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="body2"
          sx={{ cursor: "pointer", color: "#838383", fontSize: "1rem" }}
        >
          {minLabel}
        </Typography>
        <Typography
          variant="body2"
          sx={{ cursor: "pointer", color: "#838383", fontSize: "1rem" }}
        >
          {maxLabel}
        </Typography>
      </Box>
    </Box>
  )
}

export default CalculatorSlider
