import React, { useState, useRef } from "react"
import styles from "../../css/UI.module.css"
import { PDFDocument } from "pdf-lib"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import fontkit from "@pdf-lib/fontkit"
import { Spinner } from "gestalt"
import { useNavigate, useParams } from "react-router"
import {
  ChangeApprovalStatus,
  ErrorMsgLogger,
  getDocToSign,
  getUserUnsignDocs,
  uploadSignedDoc,
} from "../../firebaseConf"
import { useEffect } from "react"
import SignCanvas from "../SignCanvas/SignCanvas"
import Profile from "../Profile/Profile"
import { Paths } from "../../../utils/Paths"

import { Viewer, Worker } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"

const DocSign = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const [hasUpdated, setHasUpdated] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("") // Initial relative path to the PDF
  const pdfRef = useRef(null) // Ref to download the modified PDF

  const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null)
  const [trimmedDataUrl, setTrimmedDataUrl] = useState("")
  const [isSigned, setIsSigned] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true) // Track first render

  let { filename, id } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    getUnsignedDoc()
  }, [])

  useEffect(() => {
    if (!isFirstRender) {
      handleUpdate()
      if (trimmedDataUrl !== "" && trimmedDataUrl) {
        setTimeout(() => {
          setIsSigned(true)
        }, 3000)
      } else {
        setIsSigned(false)
        resetPDF()
      }
    } else {
      setIsFirstRender(false) // Mark as not first render after first effect run
    }
  }, [trimmedDataUrl])

  const handleUploadPDF = async () => {
    try {
      uploadDocs(modifiedPdfBytes)
      ChangeApprovalStatus(id, "processing")
    } catch (error) {
      const rs_errorLog = await ErrorMsgLogger(error.message)
      console.log(error.message)
    }
  }

  async function uploadDocs(rs) {
    console.log("start upload")
    const result = await uploadSignedDoc(rs, id, filename)
    console.log(result)
    if (result === "success") {
      navigate(Paths.userDashboard + id)
    }
  }

  const getUnsignedDoc = async () => {
    try {
      const doc_selector = await getDocToSign(id, filename)
      const filesList = doc_selector[0]?.files

      for (const element of filesList) {
        if (element.fileName === filename) {
          const response = await fetch(element.downloadURL)
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          // const pdfBytes = await response.arrayBuffer()
          // const blob = new Blob([pdfBytes], { type: "application/pdf" })
          //  setPdfUrl(URL.createObjectURL(blob))
          setPdfUrl(element.downloadURL)
          break
        }
      }
    } catch (error) {
      console.error("Error fetching or loading PDF document:", error)
    }
  }

  const resetPDF = () => {
    setIsProcessing(true)
    setTrimmedDataUrl("")
    getUnsignedDoc()
    setHasUpdated(false) // Reset hasUpdated to false
    setIsProcessing(false)
  }

  const handleUpdate = async () => {
    if (!pdfUrl) {
      console.error("PDF URL is not set")
      return
    }

    setIsProcessing(true)
    const fontBytes = await fetch("/NotoSansTC-Regular.ttf").then((res) =>
      res.arrayBuffer()
    )
    // Load the existing PDF

    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    )

    try {
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      pdfDoc.registerFontkit(fontkit)

      const customFont = await pdfDoc.embedFont(fontBytes)

      //load image bytes

      const imageBytes = await fetch(trimmedDataUrl).then((res) =>
        res.arrayBuffer()
      )
      const signImg = await pdfDoc.embedPng(imageBytes) // Embed the signature image bytes
      const imageWidth = signImg.width * 0.3
      const imageHeight = signImg.height * 0.3

      // Modify the PDF based on user input
      const pages = pdfDoc.getPages()
      const Page1 = pages[0]
      const Page2 = pages[1]
      const Page3 = pages[2]
      const Page4 = pages[3]
      const Page5 = pages[4]
      const Page6 = pages[5]
      const Page7 = pages[6]
      const Page8 = pages[7]
      const Page9 = pages[8]
      const Page10 = pages[9]
      const Page11 = pages[10]
      const Page12 = pages[11]
      const Page13 = pages[12]
      const Page14 = pages[13]
      const Page15 = pages[14]
      const Page16 = pages[15]
      const Page17 = pages[16]
      const Page18 = pages[17]
      const Page19 = pages[18]

      Page1.drawImage(signImg, {
        x: 60,
        y: 240,
        width: imageWidth,
        height: imageHeight,
      })
      Page2.drawImage(signImg, {
        x: 450,
        y: 10,
        width: imageWidth,
        height: imageHeight,
      })

      Page3.drawImage(signImg, {
        x: 250,
        y: 145,
        width: imageWidth,
        height: imageHeight,
      })
      Page4.drawImage(signImg, {
        x: 50,
        y: 70,
        width: imageWidth,
        height: imageHeight,
      })
      Page5.drawImage(signImg, {
        x: 50,
        y: 120,
        width: imageWidth,
        height: imageHeight,
      })

      Page6.drawImage(signImg, {
        x: 50,
        y: 110,
        width: imageWidth,
        height: imageHeight,
      })
      Page7.drawImage(signImg, {
        x: 50,
        y: 110,
        width: imageWidth,
        height: imageHeight,
      })
      Page8.drawImage(signImg, {
        x: 450,
        y: 10,
        width: imageWidth,
        height: imageHeight,
      })
      Page9.drawImage(signImg, {
        x: 50,
        y: 145,
        width: imageWidth,
        height: imageHeight,
      })
      Page10.drawImage(signImg, {
        x: 450,
        y: 10,
        width: imageWidth,
        height: imageHeight,
      })
      Page11.drawImage(signImg, {
        x: 450,
        y: 10,
        width: imageWidth,
        height: imageHeight,
      })
      Page12.drawImage(signImg, {
        x: 450,
        y: 10,
        width: imageWidth,
        height: imageHeight,
      })
      Page13.drawImage(signImg, {
        x: 50,
        y: 70,
        width: imageWidth,
        height: imageHeight,
      })
      Page14.drawImage(signImg, {
        x: 50,
        y: 100,
        width: imageWidth,
        height: imageHeight,
      })
      Page15.drawImage(signImg, {
        x: 280,
        y: 100,
        width: imageWidth,
        height: imageHeight,
      })
      Page16.drawImage(signImg, {
        x: 280,
        y: 155,
        width: imageWidth,
        height: imageHeight,
      })
      Page17.drawImage(signImg, {
        x: 50,
        y: 280,
        width: imageWidth,
        height: imageHeight,
      })
      Page18.drawImage(signImg, {
        x: 70,
        y: 125,
        width: imageWidth,
        height: imageHeight,
      })
      Page19.drawImage(signImg, {
        x: 110,
        y: 310,
        width: imageWidth,
        height: imageHeight,
      })

      // Serialize the modified PDF
      const modifiedPdfBytes = await pdfDoc.save()
      setModifiedPdfBytes(modifiedPdfBytes)
      // Convert it to a blob and update the state to re-render the iframe
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" })
      setPdfUrl(URL.createObjectURL(blob))
      setHasUpdated(true) // Set hasUpdated to true
      setIsProcessing(false)
    } catch (error) {
      console.error("Error loading PDF document:", error)
    }
  }

  return (
    <div className={styles.container}>
      <Profile
        back_url={Paths.userDashboard + id}
        logoLink={Paths.userDashboard + id}
      />
      <div className="contract-container mt-5">
        <div className="contract-preview-container mb-5">
          {pdfUrl && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div
                style={{
                  height: "750px",
                  width: "900px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}
          {/* <iframe
            className="contract-preview-iframe"
            src={pdfUrl}
            width={"90%"}
          ></iframe> */}
        </div>
        <h4>
          若同意及承認以上文件包括的所有條款，請在下方空白地方簽署並確認。
        </h4>
        <div className="contract-sign-canvas">
          <SignCanvas setTrimmedDataUrl={setTrimmedDataUrl} />
        </div>
        <div className="contract-input-container">
          <div className="button-container">
            {/* <Spinner show={isProcessing} accessibilityLabel="spinner" /> */}
            <Button
              variant="contained"
              className={styles.signCanvas_button}
              onClick={handleUploadPDF}
              disabled={!isSigned}
            >
              確認簽署
            </Button>
          </div>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className="mt-5"
          variant="contained"
          color="primary"
          ref={pdfRef}
          href={pdfUrl}
          download="modified_contract.pdf"
        >
          下載 PDF
        </Button>
      </div> */}
      </div>
    </div>
  )
}

export default DocSign
