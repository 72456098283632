import axios from "axios"
import useAxios, { configure } from "axios-hooks"
import Cookies from "js-cookie"

const useFetch = (url, method = "GET", options = {}) => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: url,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-apollo-operation-name": "loginOperation", // 自定義操作名稱
      "apollo-require-preflight": "true", // 設置這個標頭
    },
  })

  // 请求拦截器：在每个请求头中加入 Authorization 标头
  instance.interceptors.request.use(
    (config) => {
      const token = Cookies.get("keystonejs-session")
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  configure({ instance })

  const [{ data, loading, error }, refetch] = useAxios({
    url,
    method,
    ...options,
  })

  return { data, loading, error, refetch }
}

export default useFetch
