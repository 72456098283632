export function wrapText(text) {
  return String(text)
    .split("\n")
    .map((str, indx) => (
      <div key={indx}>
        <span className="wrap-text">{str}</span>
        <br />
      </div>
    ))
}
