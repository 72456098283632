export const EditListNames = {
  MENUS: "menus",

  HOMES: "homes",
  ABOUTS: "abouts",
  LARGE_AMOUNTS: "largeAmounts",
  PERSONAL_LOANS: "personalLoans",
  MORTGAGES: "mortgages",
  QAS: "qAS",
  METAS: "metas",
  USERS: "users",
  POSTS: "posts",
  APPLICATION_FORMS: "applicationForms",
  APPLICATION_FORM_REGISTERS: "applicationFormRegisters",
}
