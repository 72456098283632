import React, { createContext, useReducer, useEffect } from "react"
import Cookies from "js-cookie"

const initialState = {
  loggedIn: false,

  userRole: null,
}

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        loggedIn: true,
        userRole: action.payload.userRole,
      }
    case "LOGOUT":
      localStorage.clear()
      // Clear the "keystonejs-session" cookie
      Cookies.remove("keystonejs-session")
      return {
        ...state,
        loggedIn: false,

        userRole: null,
      }
    default:
      return state
  }
}

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  useEffect(() => {
    const keystoneSession = Cookies.get("keystonejs-session")
    if (keystoneSession) {
      const storedState = JSON.parse(localStorage.getItem("authState"))
      if (storedState) {
        dispatch({ type: "LOGIN", payload: storedState })
      }
    }
  }, [])

  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem("authState", JSON.stringify(state))
    } else {
      localStorage.removeItem("authState")
    }
  }, [state])

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
