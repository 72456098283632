import React, { useEffect } from "react"
import css from "./css/supportDoc.module.css"
import { wrapText } from "../utils/wrapText"
import { useQuery } from "@apollo/client"
import { GET_METAS } from "../graphql/Meta/META_GQL"
import { findContentByLabel } from "../utils/findContentByLabel"

const PrivacyPolicy = () => {
  const { loading, error, data } = useQuery(GET_METAS)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const content = findContentByLabel(data?.metas, "privacyPolicy")

  return (
    <div className={css.container}>
      <h2 className="pb-5">私隱政策</h2>

      {loading && <p>Loading...</p>}
      <div className={css.content}>{wrapText(content)}</div>
    </div>
  )
}

export default PrivacyPolicy
