// ServiceError.js
import React from "react"

const ServiceError = () => {
  return (
    <div style={{ height: "70vh" }}>
      <h1>服務錯誤</h1>
      <p>很抱歉，發生了一個錯誤。請稍後再試。</p>
    </div>
  )
}

export default ServiceError
