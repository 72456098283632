import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router"

import {
  getUserSignedDocs,
  getUserUnsignDocs,
  listenUnsignDocs,
  listenSignedDocs,
} from "../../firebaseConf"
import { useEffect, useState } from "react"
import { Table, Text, Spinner, Box } from "gestalt"
import "gestalt/dist/gestalt.css"
import { Paths } from "../../../utils/Paths"

const ClientSigned = () => {
  const [error, setError] = useState(false)
  const [docs, setDocs] = useState([])
  const [signedDocs, setSignedDocs] = useState([])
  const [show, setShow] = useState(true)

  let num = 0
  let { id } = useParams()
  useEffect(() => {
    const unsubscribeUnsignDocs = listenUnsignDocs(id, (docs) => {
      // Modified: Setup real-time listener
      setDocs(docs)
      setShow(false)
    })

    const unsubscribeSignedDocs = listenSignedDocs(id, (signedDocs) => {
      // Modified: Setup real-time listener
      setSignedDocs(signedDocs)
      setShow(false)
    })

    return () => {
      unsubscribeUnsignDocs()
      unsubscribeSignedDocs()
    }
  }, [id]) // Modified: Dependency on id
  return (
    <div className={styles.container}>
      <Profile
        back_url={Paths.esignClientList}
        logoLink={Paths.esignDashboard}
      />
      {show ? (
        <Spinner show={show} accessibilityLabel="spinner" />
      ) : (
        <div className="input_container">
          {/* Supporting File */}
          <div className="user-control-row">
            <h2>已上載文件</h2>
          </div>
          <hr className={styles.divider}></hr>
          {docs[0] ? (
            <Box width="100%">
              <Table accessibilityLabel="Sticky header" maxHeight={500}>
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>文件名稱</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>更新時間</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold"></Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {docs[0]?.files
                    .filter((doc) => !doc?.fileName.includes("_contract"))
                    .reverse()
                    .map((doc, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <a href={doc?.downloadURL} target="_blank">
                                <p>
                                  {++num}. {doc?.fileName}
                                </p>
                              </a>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <p>{doc?.timestamp}</p>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          ) : (
            <h3>沒有文件</h3>
          )}

          <br />
          <h2>已簽文件</h2>
          <hr className={styles.divider}></hr>
          <Box width="100%">
            <Table accessibilityLabel="Sticky header" maxHeight={500}>
              <Table.Header sticky>
                <Table.Row>
                  <Table.HeaderCell>
                    <Text weight="bold">文件名稱</Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text weight="bold">上載時間</Text>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {signedDocs[0]?.files.reverse().map((doc, idx) => {
                  return (
                    <Table.Row key={doc?.docId}>
                      <Table.Cell>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <a href={doc?.downloadURL} target="_blank">
                            <p>
                              {++num}. {doc?.fileName}
                            </p>
                          </a>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <p>{doc?.timestamp}</p>
                        </div>
                      </Table.Cell>{" "}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Box>
        </div>
      )}
    </div>
  )
}

export default ClientSigned
