import phone_icon from "../../images/icon/phone_icon_white.png"
import "./phoneBtn.css"
const PhoneBtn = ({ phone }) => {
  return (
    <div className="phoneBtn">
      <div className="phoneBtn-container">
        <a href={`tel:+852${phone}`}>
          <img src={phone_icon} className="phoneIcon" alt="" />
        </a>
      </div>
    </div>
  )
}

export default PhoneBtn
