const authenticateUserWithPasswordMutation = (email, password) => {
  return {
    query: `
      mutation AuthenticateUserWithPassword($email: String!, $password: String!) {
        authenticateUserWithPassword(email: $email, password: $password) {
          ... on UserAuthenticationWithPasswordSuccess {
            sessionToken
            item {
          id
          name
          email
          isAdmin
          showCMS
          showEsignature
        }
          }
          ... on UserAuthenticationWithPasswordFailure {
            message
          }
        }
      }
    `,
    variables: {
      email,
      password,
    },
  }
}

export { authenticateUserWithPasswordMutation }
