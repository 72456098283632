import { useNavigate } from "react-router-dom"
import FeaturesSection from "./FeaturesSection/FeaturesSection"
import { Paths } from "../utils/Paths"
import css from "./css/homeSection2Feature.module.css"
import { findContentByLabel } from "../utils/findContentByLabel"

const HomeSection2Feature = ({ data }) => {
  const navigate = useNavigate()
  const section2Title1 = findContentByLabel(data, "section2_title1") || ""
  const section2Title2 = findContentByLabel(data, "section2_title2") || ""
  const section2Title3 = findContentByLabel(data, "section2_title3") || ""
  return (
    <div className={css.container}>
      <div className={css.section1}>
        <h5>{section2Title1}</h5>
        <h2>{section2Title2}</h2>
        <h5>{section2Title3}</h5>
      </div>

      <FeaturesSection />
      <button
        className={`applyBtn-lg animatedBtn`}
        onClick={() => navigate(Paths.userLogin)}
      >
        立即申請
      </button>
    </div>
  )
}

export default HomeSection2Feature
