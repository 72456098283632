import { useNavigate } from "react-router-dom"
import HomePart2Box from "../compoients/HomePart2Box"
import StepInfoTab from "../compoients/StepInfoTab"
import { wrapText } from "../utils/wrapText"
import css from "./css/mortgage.module.css"
import { Paths } from "../utils/Paths"
import QaTabs from "../compoients/QaTabs"

const Mortgage = () => {
  const navigate = useNavigate()
  const bannerTitle = "樓宇按揭"
  const bannerDesc = `[CREDIT PRO]致力於為客戶提供個性化、靈活的抵押貸款，無需查看客戶的信用評級報告（TU）。無需擔心申請貸款對其信用評級的影響，讓客戶可以節省更多時間，使用轉更靈活、更快捷的同時，為滿足不同客戶的需求，[CREDIT PRO]提供多種貸款方案和多種按揭方式，審批速度快 安排隨同一天提取貸款。`
  return (
    <div className={css.container}>
      <div className={css.banner_wrapper}>
        <div className={css.banner_content}>
          <h4>{bannerTitle}</h4>
          <p>{wrapText(bannerDesc)}</p>
        </div>
      </div>
      <div className={css.page_content}>
        <div className={css.section1}>
          <h5>極速處理，即批即過戶</h5>
          <h2 style={{ fontWeight: "700" }}>
            全自動簡化貸款過程貸款申請無間斷
          </h2>
          <h5>為你提供最合理合適報價</h5>
          <HomePart2Box />
          <div style={{ marginTop: "5rem", marginBottom: "4rem" }}>
            <StepInfoTab />
          </div>
          <button
            className={`applyBtn-lg animatedBtn`}
            onClick={() => navigate(Paths.userLogin)}
          >
            立即申請
          </button>
        </div>
        <div className="d-flex flex-column align-items-center mb-5 mt-5">
          <h2 style={{ fontWeight: "600", color: "white" }}>申請Q&A</h2>
          <QaTabs />
        </div>
      </div>
    </div>
  )
}

export default Mortgage
