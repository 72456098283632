import React, { useState } from "react"
import "./css/qaTabs.css"
import RecatAccordion from "../compoients/ReactAccordion"
// import { data1, data2, data3, data4, data5 } from "./QandAContent"

export const QaTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0) // Default to the first tab

  const categories = [
    { label: "一般申請事項", element: <RecatAccordion /> },
    // { label: "貸款事宜", element: <RecatAccordion data={data2} /> },
    // { label: "提取貸款後", element: <RecatAccordion data={data3} /> },
    // { label: "其他貸款Q&A", element: <RecatAccordion data={data4} /> },
    // { label: "汽車貸款", element: <RecatAccordion data={data5} /> },
  ]

  return (
    <div className="tabs-container">
      {/* <div className="tab-container">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`tab ${index === selectedTab ? "selected" : ""}`}
            onMouseOver={(e) => e.currentTarget.classList.add("hover")}
            onMouseOut={(e) => e.currentTarget.classList.remove("hover")}
            onClick={() => setSelectedTab(index)}
          >
            {category.label}
          </div>
        ))}
      </div> */}

      {categories.map(
        (category, index) =>
          index === selectedTab && (
            <div key={index} className="tab-content">
              {category.element}
            </div>
          )
      )}
    </div>
  )
}

export default QaTabs
