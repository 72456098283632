import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import css from "./css/blogPostPage.module.css"
import useFetch from "../hooks/useFetch"

import { FormDatatext } from "../hooks/useFormatDatatext"
import { useQuery } from "@apollo/client"
import { GET_POSTS } from "../graphql/Post/POST_GQL"
// import { DocumentRenderer } from "@keystone-6/document-renderer"

const BlogPostPage = () => {
  const { loading, error, data } = useQuery(GET_POSTS)
  const { postId } = useParams()
  const [post, setPost] = useState(null)
  const [posts, setPosts] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  useEffect(() => {
    if (data) {
      setPosts(data?.posts)
    }
  }, [data])

  useEffect(() => {
    if (posts) {
      const foundPost = posts.find((post) => post.id === postId)
      setPost(foundPost)
    }
    console.log(posts)
  }, [posts, postId])

  const handleThumbnailClick = (clickedPostId) => {
    navigate(`/blog/${clickedPostId}`) // Navigate to the specific blog post page
    window.scrollTo({ top: 50, behavior: "smooth" })
  }

  if (loading) return <p>請等待一下...</p>
  if (error) return <p>Error: {error.message}</p>

  const filteredPosts = posts?.filter((p) => p.id !== postId)

  return (
    <div className={`${css.blog_post_page} fade-in-element`}>
      <div className={`${css.left_side}`}>
        <h1 className="color4 pb-2">{post?.title}</h1>
        <img src={post?.banner?.url} alt={post?.title} />
        <p>{FormDatatext(post?.content)}</p>
      </div>
      <div className={css.right_side}>
        <h2 className="color4">為你推薦</h2>
        <hr></hr>
        {filteredPosts?.map((post) => (
          <div
            key={post?.id}
            className={css.thumbnail}
            onClick={() => handleThumbnailClick(post?.id)} // Pass the post ID to the click handler
          >
            <img src={post?.thumbnail?.url} alt={post.title} />
            <p>{post?.title}</p>
            <p>{post?.content.slice(0, 16)}...</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogPostPage
