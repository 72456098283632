export function loanCalculator(principal, minRate, maxRate, period) {
  const calculateMonthlyPay = (rate) => {
    const monthlyRate = rate / 100 / 12
    return (
      (principal * monthlyRate) / (1 - Math.pow(1 / (1 + monthlyRate), period))
    )
  }

  const minMonthlyPay = calculateMonthlyPay(minRate)
  const maxMonthlyPay = calculateMonthlyPay(maxRate)

  return {
    minMonthlyPay: Math.round(minMonthlyPay),
    maxMonthlyPay: Math.round(maxMonthlyPay),
  }
}
