import { useRef, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import css from "./css/home.module.css"
import "./css/home.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Carousel } from "primereact/carousel"
import "../animation.css"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { VideoPlayer } from "../compoients/VideoPlayer"

import sound_icon from "../images/icon/sound.png"
import mute_icon from "../images/icon/mute.png"
import CloseIcon from "@mui/icons-material/Close"
import VolumeOffIcon from "@mui/icons-material/VolumeOff"

import LoanProductSwiper from "../compoients/LoanProductSwiper"
import CalculatorSection from "../compoients/CalculatorSection"
import QaTabs from "../compoients/QaTabs"
import { loanProductData } from "../data/loanProduct"
import { useQuery } from "@apollo/client"
import { GET_HOMES } from "../graphql/Home/HOME_GQL"
import { useLocation } from "react-router-dom"
import banner768 from "../images/home/banner768.png"
import banner1440 from "../images/home/banner1440.png"
import banner1920 from "../images/home/banner1920.png"
import banner2560 from "../images/home/banner2560.png"

import { wrapText } from "../utils/wrapText"
import { Modal, Box, IconButton } from "@mui/material"
import { VideoPlayerMobile } from "../compoients/VideoPlayerMobile"
import "../compoients/css/videoPlayer.css"
import useDeviceQueries from "../hooks/useDeviceQueries"
import { findContentByLabel } from "../utils/findContentByLabel"
import SeparatedSection from "../compoients/SeparatedSection"
import FeaturesSection from "../compoients/FeaturesSection/FeaturesSection"
import { Paths } from "../utils/Paths"
import StepInfoTab from "../compoients/StepInfoTab"
import ballEffect from "../images/home/part-1bg.png"
import ballEffect2 from "../images/home/Part-2bg.png"
import HomePart2Box from "../compoients/HomePart2Box"
import QuoteSection from "../compoients/QuoteSection"
import HomeSection2Feature from "../compoients/HomeSection2Feature"

gsap.registerPlugin(ScrollTrigger)

const Home = ({ isClosed }) => {
  const {
    isDesktop,
    isTablet,
    isMobile,
    isLaptop,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
    is1024_768,
    is1280_1024,
    is1920_1080,
    is2560_1440,
    is2560_1080,
  } = useDeviceQueries()

  const navigate = useNavigate()

  let ContentData = useQuery(GET_HOMES)
  ContentData = ContentData?.data?.homes

  const [sortedData, setSortedData] = useState([])
  const [productData, setProductData] = useState(null)
  const [showVideo, setShowVideo] = useState(false)

  const videoWidth = "100%"
  const videoHeight = "100vh"
  const videoScale = "1.5"

  const videoWidth_mob = "100%"
  const videoHeight_mob = "120vh"
  const videoScale_mob = "4.8"

  const VideoScale_Tablet = "4"

  const bannerTextRef = useRef(null)
  const bannerText2Ref = useRef(null)
  const qaRef = useRef(null)

  const backgroundImage = isMobile
    ? banner768
    : isTablet
    ? banner768
    : isDesktop1440
    ? banner1440
    : isDesktop1920
    ? banner1920
    : isDesktop2560
    ? banner2560
    : banner1440 // 在没有其他条件匹配时的默认背景

  useEffect(() => {
    setTimeout(() => {
      setShowVideo(true)
    }, 3000)
  }, [])

  useEffect(() => {
    if (!productData) {
      setProductData(sortedData[0])
    }
  }, [productData, sortedData])

  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1))
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" })
        }, 100)
      }
    }
  }, [hash])

  useEffect(() => {
    const handleScroll = () => {
      // window.scrollTo({ top: 0, behavior: "instant" })
    }
    window.addEventListener("beforeunload", handleScroll)

    return () => {
      window.removeEventListener("beforeunload", handleScroll)
    }
  }, [])

  /*video*/
  const [muteImg, setMuteImg] = useState(mute_icon)
  const iframeRef = useRef(null) // Ref to hold the iframe element
  const playerRef = useRef(null) // Ref to hold the Vimeo player instance

  const [deviceSystem, setDeviceSystem] = useState("")

  useEffect(() => {
    const userAgent = navigator.userAgent
    let system = ""

    if (userAgent.match(/Windows/i)) {
      system = "Windows"
    } else if (userAgent.match(/Mac/i)) {
      system = "macOS"
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      system = "iOS"
    } else if (userAgent.match(/Android/i)) {
      system = "Android"
    }
    setDeviceSystem(system)
  }, [])

  const [open, setOpen] = useState(false)
  const videoRef = useRef(null)

  // Play the video as soon as the page loads
  useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }
  const video_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    border: "none",
    outline: "none",
  }

  //mute and unmute video
  useEffect(() => {
    if (iframeRef.current) {
      // Access the Vimeo Player from the global window object
      const player = new window.Vimeo.Player(iframeRef.current)

      // Store the player instance in playerRef
      playerRef.current = player

      // Set the initial state to muted
      player.setVolume(0).then(() => {
        setMuteImg(mute_icon) // Ensure the icon shows muted
      })

      // Set the state based on the current volume
      player.getVolume().then((volume) => {
        if (volume === 0) {
          setMuteImg(mute_icon) // Ensure the icon shows muted
        } else {
          setMuteImg(sound_icon) // If unmuted, show the unmute icon
        }
      })
    }
  }, []) // Empty dependency array to run this effect only once on mount

  const handleMuteClick = () => {
    const player = playerRef.current
    if (player) {
      player.getVolume().then((volume) => {
        if (volume === 0) {
          player.setVolume(1)
          setMuteImg(sound_icon) // Change icon to sound icon
        } else {
          player.setVolume(0)
          setMuteImg(mute_icon) // Change icon to mute icon
        }
      })
    } else {
      console.error("Player is not initialized yet")
    }
  }
  /* end video*/

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const [pageYOffset, setPageYOffset] = useState(0)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [isScrollUp, setIsScrollUp] = useState(false)

  useEffect(() => {
    function handleScroll() {
      const currentScrollTop = window.scrollY
      if (currentScrollTop > lastScrollTop) {
        setIsScrollUp(false)
      } else {
        setIsScrollUp(true)
      }
      setLastScrollTop(currentScrollTop)
      setPageYOffset(currentScrollTop)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollTop])

  //section 2 setting

  const section2_ref = useRef(null)

  const responsiveOptions = [
    {
      breakpoint: "800px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ]

  // useEffect(() => {
  //   // console.log("pageYOffset", pageYOffset)

  //   if (isDesktop || isLaptop) {
  //     if (pageYOffset < 800) {
  //       section2_ref.current.classList.remove("fixedSection")
  //       section2_ref.current.classList.add("absolueSection")
  //     }
  //     if (pageYOffset > 1850) {
  //       section2_ref.current.classList.remove("fixedSection")
  //       section2_ref.current.classList.add("absolueSection2")
  //     }

  //     if (pageYOffset >= 0) {
  //       section2_ref.current.classList.add("shown")
  //     }

  //     if (pageYOffset >= 800 && pageYOffset <= 1200) {
  //       section2_ref.current.classList.add("fixedSection")
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")

  //       setProductData(sortedData[0])
  //     }
  //     if (pageYOffset >= 1201 && pageYOffset <= 1500) {
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //       setProductData(sortedData[1])
  //     }
  //     if (pageYOffset >= 1501 && pageYOffset <= 1850) {
  //       section2_ref.current.classList.add("fixedSection")
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //       setProductData(sortedData[2])
  //     }
  //   } else if (isTablet) {
  //     if (pageYOffset <= 500) {
  //       section2_ref.current.classList.remove("fixedSection")
  //       section2_ref.current.classList.add("absolueSection")
  //     }
  //     if (pageYOffset > 1600) {
  //       section2_ref.current.classList.remove("fixedSection")
  //       section2_ref.current.classList.add("absolueSection2")
  //     }

  //     if (pageYOffset >= 0) {
  //       section2_ref.current.classList.add("shown")
  //     }

  //     if (pageYOffset >= 500 && pageYOffset <= 1200) {
  //       setProductData(sortedData[0])
  //       section2_ref.current.classList.add("fixedSection")
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //     }
  //     if (pageYOffset >= 1201 && pageYOffset <= 1500) {
  //       setProductData(sortedData[1])
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //     }
  //     if (pageYOffset >= 1501 && pageYOffset <= 1600) {
  //       setProductData(sortedData[2])
  //       section2_ref.current.classList.add("fixedSection")
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //     }
  //   } else if (isMobile) {
  //     /*******mobile version********/

  //     if (pageYOffset < 720) {
  //       section2_ref.current.classList.remove("fixedSection")
  //       section2_ref.current.classList.add("absolueSection")
  //     }

  //     if (pageYOffset >= 0) {
  //       section2_ref.current.classList.add("shown")
  //     }

  //     if (pageYOffset >= 720 && pageYOffset <= 1200) {
  //       setProductData(sortedData[0])
  //       section2_ref.current.classList.add("fixedSection")
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //     }
  //     if (pageYOffset >= 1201 && pageYOffset <= 1500) {
  //       setProductData(sortedData[1])
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //     }
  //     if (pageYOffset >= 1501 && pageYOffset <= 1950) {
  //       setProductData(sortedData[2])
  //       section2_ref.current.classList.add("fixedSection")
  //       section2_ref.current.classList.remove("absolueSection")
  //       section2_ref.current.classList.remove("absolueSection2")
  //     }
  //     if (pageYOffset > 1950) {
  //       section2_ref.current.classList.remove("fixedSection")
  //       section2_ref.current.classList.add("absolueSection2")
  //     }
  //   }

  //   //base of pagge offset to add class to useref by using gsap
  // }, [pageYOffset])

  // useEffect(() => {
  //   if (isDesktop || isLaptop || isTablet || isMobile) {
  //     // Initial setup for invisible state
  //     gsap.set(bannerTextRef.current, {
  //       opacity: 0,
  //       y: -50,
  //     })
  //     gsap.set(bannerText2Ref.current, {
  //       opacity: 0,
  //       y: 50,
  //     })
  //     gsap.set(qaRef.current, {
  //       opacity: 0,
  //       y: 50,
  //     })

  //     // Fade-in animation on load
  //     gsap.to(bannerTextRef.current, { opacity: 1, duration: 1, y: 0 })
  //     gsap.to(bannerText2Ref.current, {
  //       opacity: 1,
  //       duration: 1,
  //       delay: 0.2,
  //       y: 0,
  //     })

  //     gsap.to(qaRef.current, {
  //       y: 0,
  //       opacity: 1,
  //       duration: 1,
  //       delay: 0.2,
  //       scrollTrigger: {
  //         trigger: qaRef.current,
  //         start: "top 80%", // Adjust the position to trigger the animation
  //         end: "top 50%", // Optional: Define the end position
  //         scrub: false, // Disable scrub for a simple fade-in
  //         once: true, // Only trigger the animation once
  //       },
  //     })
  //   }
  // }, [isDesktop, isLaptop, isTablet, isMobile])

  // useEffect(() => {
  //   if (isTablet) {
  //     section2_ref.current.classList.add("shown")
  //   }
  // }, [])

  /* end of section setting */

  return (
    <div className="home" style={{ marginTop: isClosed ? "0rem" : "5rem" }}>
      {/* Section 1 */}
      <div className="home_section1">
        {(isDesktop || isLaptop) && (
          <div className={`${css.container}`}>
            <div className={css.videoBg_container}>
              <div className={css.overlay}></div>
              {/* {VideoPlayer(deviceSystem, videoRef)} */}
              {!showVideo && (
                <div
                  className={css.videoPic}
                  style={{ display: "block" }}
                ></div>
              )}

              <iframe
                ref={iframeRef}
                src="https://player.vimeo.com/video/1024833178?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&muted=1&loop=1&controls=0&quality=480p"
                frameborder="0"
                allow="autoplay; fullscreen;"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  width: videoWidth,
                  height: videoHeight,
                  scale: videoScale,
                  overflow: "hidden",
                  objectFit: "cover",
                }}
                title="promo_mob_video"
              ></iframe>
            </div>
            <SeparatedSection />
          </div>
        )}
        {(isMobile || isTablet) && (
          <div className={`${css.container_mob}`}>
            {/* {VideoPlayerMobile(deviceSystem, videoRef)} */}
            <div className={css.videoBg_container}>
              <div className={css.overlay}></div>
              {/* {VideoPlayer(deviceSystem, videoRef)} */}
              {!showVideo && (
                <div
                  className={css.videoPic}
                  style={{ display: "block" }}
                ></div>
              )}

              <iframe
                ref={iframeRef}
                src="https://player.vimeo.com/video/1024833178?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&muted=1&loop=1&controls=0&quality=480p"
                frameborder="0"
                allow="autoplay; fullscreen;"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  width: videoWidth_mob,
                  height: videoHeight_mob,
                  scale: isTablet ? VideoScale_Tablet : videoScale_mob,
                  overflow: "hidden",
                  objectFit: "cover",
                }}
                title="promo_mob_video"
              ></iframe>
            </div>
            <SeparatedSection data={ContentData} />
          </div>
        )}
      </div>
      <div className="home_section2">
        <HomeSection2Feature data={ContentData} />

        <div
          className="item_container"
          style={{
            marginTop: "6rem",
            width: "100%",
            backgroundImage: `url(${ballEffect2})`,
            backgroundSize: "contain",
            backgroundPosition: "center 0px",
            backgroundRepeat: "no-repeat",
            minHeight: "80vh",
            overflow: "hidden",
            overlay: "hidden",
          }}
        >
          <StepInfoTab />
          <HomePart2Box />
        </div>

        <div
          className="item_container"
          style={{
            width: "100%",
          }}
        >
          <QuoteSection />
        </div>
        <div
          ref={qaRef}
          className="item_container"
          style={{
            width: "100%",
            backgroundImage: `url(${ballEffect})`,
            backgroundSize: "contain",
            backgroundPosition: "center 0px",
            backgroundRepeat: "no-repeat",
            minHeight: "60vh",
            overflow: "hidden",
            overlay: "hidden",
            paddingBottom: isMobile ? "3rem" : "5rem",
            marginTop: isMobile || isTablet ? "3rem" : "5rem",
          }}
        >
          <h2 style={{ fontWeight: "600", color: "white" }}>申請Q&A</h2>
          <QaTabs />
        </div>
      </div>
    </div>
  )
}

export default Home
