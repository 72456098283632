import React, { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthContext } from "../../AuthContext"

const ProtectedRoute = ({ role, redirectPath = "/", children }) => {
  const { state } = useContext(AuthContext)

  if (!state.loggedIn) {
    return <Navigate to={redirectPath} replace />
  }

  if (state.userRole !== role) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
