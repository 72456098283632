import { gql } from "@apollo/client"

export const GET_MENUS = gql`
  query Menus {
    menus {
      id
      label
      url
      order
    }
  }
`

export const CREATE_MENU = gql`
  mutation CreateMenu($data: MenuCreateInput!) {
    createMenu(data: $data) {
      id
      label
      url
      order
    }
  }
`
export const UPDATE_MENU = gql`
  mutation UpdateMenu($where: MenuWhereUniqueInput!, $data: MenuUpdateInput!) {
    updateMenu(where: $where, data: $data) {
      id
      label
      url
      order
    }
  }
`
export const DELETE_MENU = gql`
  mutation DeleteMenu($where: MenuWhereUniqueInput!) {
    deleteMenu(where: $where) {
      id
      label
      url
      order
    }
  }
`
export const DELETE_MENUS = gql`
  mutation DeleteMenus($where: [MenuWhereUniqueInput!]!) {
    deleteMenus(where: $where) {
      id
      label
      url
      order
    }
  }
`
