import { useEffect } from "react"
import PropTypes from "prop-types"

const GoogleTagManager = ({ id, onLoad }) => {
  useEffect(() => {
    if (!id) return

    // 檢查腳本是否已經存在，避免重複加載
    if (
      document.querySelector(
        `script[src="https://www.googletagmanager.com/gtag/js?id=${id}"]`
      )
    ) {
      return
    }

    // 加載 Google Tag Manager 腳本
    const script = document.createElement("script")
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    script.async = true
    script.onload = () => {
      // 初始化 gtag
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("js", new Date())
      gtag("config", id)

      // 調用 onLoad 回調
      if (typeof onLoad === "function") {
        onLoad()
      }
    }
    document.head.appendChild(script)
  }, [id, onLoad])

  return null
}

GoogleTagManager.propTypes = {
  id: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
}

export default GoogleTagManager
