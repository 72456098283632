import React, { useEffect } from "react"
import css from "./css/supportDoc.module.css"
import { wrapText } from "../utils/wrapText"
import { useQuery } from "@apollo/client"
import { GET_METAS } from "../graphql/Meta/META_GQL"
import { findContentByLabel } from "../utils/findContentByLabel"

const LendersOrdinance = () => {
  const { loading, error, data } = useQuery(GET_METAS)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const content = findContentByLabel(data?.metas, "lendersOrdinance")

  return (
    <div className={css.container}>
      <h2 className="pb-5">放債人條例</h2>
      {loading && <p>Loading...</p>}
      <div className={css.content}>{wrapText(content)}</div>
    </div>
  )
}

export default LendersOrdinance
