import css from "./css/footer.module.css"
import logo from "../images/icon/logo.png"
import wtsapp_logo from "../images/icon/whatsapp.png"
import fb_logo from "../images/icon/fb.png"
import insta_logo from "../images/icon/instagram.png"
import { HashLink } from "react-router-hash-link"
import { useMediaQuery } from "react-responsive"
import { Form, useNavigate } from "react-router-dom"
import { FormDatatext } from "../hooks/useFormatDatatext"
import { useQuery } from "@apollo/client"
import { GET_METAS } from "../graphql/Meta/META_GQL"
import { useEffect, useState } from "react"
import { findContentByLabel } from "../utils/findContentByLabel"
import { Paths } from "../utils/Paths"
import { wrapText } from "../utils/wrapText"
import useDeviceQueries from "../hooks/useDeviceQueries"

const Footer = () => {
  const {
    isDesktop,
    isTablet,
    isLaptop,
    isMobile,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  } = useDeviceQueries()

  const { loading, error, data } = useQuery(GET_METAS)
  let metasData = data?.metas

  const navigate = useNavigate()

  const license = findContentByLabel(metasData, "license") || "1227/2023"
  const reportHotLine =
    findContentByLabel(metasData, "reportHotline") || "2153 1523"

  const whatsappNum =
    findContentByLabel(metasData, "whatsappNum") || "5136 4550"
  const mailAddress =
    findContentByLabel(metasData, "mailAddress") || "creditpro2023@gmail.com"
  const openTime =
    findContentByLabel(metasData, "openTime") || "星期一至星期日 - 10:00-18:00"
  const facebookLink = findContentByLabel(metasData, "facebookLink")
  const address =
    findContentByLabel(metasData, "address") ||
    "地址：Room 11, 12/F, Tower 3, China Hong Kong City, Tsim Sha Tsui\n尖沙咀中港城3座12樓11室"
  const remarksText = findContentByLabel(metasData, "remarksText")
  const address_eng = findContentByLabel(metasData, "address_eng")
  const footer_remarks = findContentByLabel(metasData, "footer_remarks")

  const adviceSlogan = findContentByLabel(metasData, "adviceSlogan")

  const phone = ""

  function formatPhoneNumber(number) {
    if (!number || typeof number !== "string") {
      return "" // or return a default value or handle this case differently
    }
    return number.slice(0, 4) + " " + number.slice(4)
  }

  return (
    <div className={css.container}>
      <div className={css.remarks_wrapper}>
        <div className={css.remarks_content}>{wrapText(remarksText)}</div>
      </div>

      <div className={css.footer_wrapper}>
        <div className={css.logo_wrapper}>
          <a>
            <img
              src={logo}
              alt="logo"
              className={css.logo}
              onClick={() => {
                navigate("/")
              }}
            />
          </a>
        </div>

        <div className={css.footer_content}>
          <h1>{adviceSlogan}</h1>
          <h2>放債人牌照號碼：{license}</h2>
          <div className="pt-3">
            客戶服務/投訴熱線：
            <a href={`tel:+852${reportHotLine}`}>
              {formatPhoneNumber(reportHotLine)}
            </a>
          </div>
          <div>
            Whatsapp:&nbsp;
            <a
              href={`https://api.whatsapp.com/send/?phone=852${whatsappNum}`}
              target="_blank"
            >
              {formatPhoneNumber(whatsappNum)}
            </a>
          </div>
          <div>
            電子郵件：
            <a href={`mailto:${mailAddress}`}>{mailAddress}</a>
          </div>
          <div>辨公時間：{openTime}</div>
          <div style={{ textAlign: "center" }}>{wrapText(address)}</div>
        </div>
      </div>

      <div className={css.page_link}>
        <HashLink smooth to={Paths.userLogin}>
          <span>網上申請</span>
        </HashLink>
        <span className={css.divide}>|</span>
        <HashLink smooth to={Paths.qa}>
          <span>常見問題</span>
        </HashLink>
        <span className={css.divide}>|</span>
        <HashLink smooth to={Paths.largeAmount}>
          <span>債務一筆清</span>
        </HashLink>
        <span className={css.divide}>|</span>
        <HashLink smooth to={Paths.privacyPolicy}>
          <span>隱私條例</span>
        </HashLink>
        <span className={css.divide}>|</span>
        <HashLink smooth to={Paths.disclaimer}>
          <span>免責聲明</span>
        </HashLink>
        <span className={css.divide}>|</span>
        <HashLink smooth to={Paths.lendersOrdinance}>
          <span>放債人條例</span>
        </HashLink>

        {/* <div className="d-flex justify-content-end mt-3">
              <a href={facebookLink} target="_blank">
                <img
                  src={fb_logo}
                  alt="facebooklogo"
                  className={css.social_logo}
                />
              </a>
              <img
                src={insta_logo}
                alt="instagramlogo"
                className={css.social_logo}
              />
              <a
                href={`https://api.whatsapp.com/send/?phone=852${whatsappNum}`}
                target="_blank"
              >
                <img
                  src={wtsapp_logo}
                  alt="whatsapplogo"
                  className={css.social_logo}
                />
              </a>
            </div> */}
      </div>
      {/* <div className="d-flex justify-content-center mt-3 ">
            <hr className={css.divider} />
          </div> */}
    </div>
  )
}

export default Footer
