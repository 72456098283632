import { Paths } from "../utils/Paths"

export const mainMenu = [
  {
    label: "首頁",
    url: Paths.home,
    order: 1,
  },

  { label: "關於我們", url: Paths.aboutUs, order: 2 },
  {
    label: "債務一筆清",
    url: Paths.largeAmount,
    order: 3,
  },
  { label: "樓宇按揭", url: Paths.mortgage, order: 4 },
  {
    label: "私人貸款",
    url: Paths.personalLoan,
    order: 5,
  },

  { label: "常見問題", url: Paths.qa, order: 6 },
  // { label: "Blog", url: Paths.blog, order: 7 },
]

export const subMenu = [
  {
    label: "免TU貸款",
    url: "/tu",
    order: 1,
  },
  { label: "私人貸款", url: "/personalloan" },
  {
    label: "業主貸款或物業按揭",
    url: "/mortgage",
    order: 2,
  },
  {
    label: "賬戶結餘或清卡數",
    url: "/creditcard",
    order: 3,
  },
]
