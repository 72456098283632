import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router"
import { Form, FormControl, InputGroup } from "react-bootstrap"
import Alert from "@mui/material/Alert"
import CheckIcon from "@mui/icons-material/Check"
import "./clientDetail.css"
import { uploadFile, listenUnsignDocs, deleteUserDoc } from "../../firebaseConf"
import { useEffect, useState } from "react"
import { Table, Text, Spinner, Box } from "gestalt"
import "gestalt/dist/gestalt.css"
import { confirmAlert } from "react-confirm-alert"
import CreateContract from "../CreateContract/CreateContract"
import EditContract from "../EditContract/EditContract"
import { Paths } from "../../../utils/Paths"

const ClientDetail = () => {
  const [modifiedPdf, setModifiedPdf] = useState(null)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [docs, setDocs] = useState([])
  const [show, setShow] = useState(true)

  const [isModifiedPdf, setIsModifiedPdf] = useState(false)

  //editable contract variables
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const isEditable = false
  const isDemo = false
  useEffect(() => {
    setIsButtonDisabled(isDemo || (isEditable && !isModifiedPdf))
  }, [isModifiedPdf])

  let num = 0
  let { id } = useParams()

  useEffect(() => {
    if (modifiedPdf) {
      setIsModifiedPdf(true)
    }
  }, [modifiedPdf])

  useEffect(() => {
    const unsubscribe = listenUnsignDocs(id, (docs) => {
      // Setup real-time listener
      setDocs(docs)
      setShow(false)
    })

    return () => unsubscribe() // Cleanup listener
  }, [id])

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setModifiedPdf(file)
    setIsButtonDisabled(!file) // 如果未选择文件则禁用按钮
  }

  const handleUpload = async (e) => {
    e.preventDefault()
    if (!modifiedPdf) {
      setError(true)
      setUploadSuccess(false)
      return
    }

    setIsModifiedPdf(false)
    setShow(true) //loading
    setError(false)
    setUploadSuccess(false)
    setModifiedPdf(null)

    async function uploadDocs() {
      const rs = await uploadFile(modifiedPdf, id)
      if (rs === "success") {
        setUploadSuccess(true)
        setError(false)
      } else {
        setError(true)
        setUploadSuccess(false)
      }
      setShow(false)
    }

    uploadDocs()
  }

  const handleDeleteDoc = (e, fileName) => {
    e.preventDefault()
    async function deleteDoc() {
      const rs = await deleteUserDoc(id, fileName)
    }
    confirmAlert({
      title: "刪除文件",
      message: "請確認是否刪除" + fileName + "?",
      buttons: [
        {
          label: "確認",
          onClick: () => {
            setTimeout(deleteDoc, 1000)
          },
        },
        {
          label: "取消",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  return (
    <div className={styles.container}>
      <Profile
        back_url={Paths.esignClientList}
        logoLink={Paths.esignClientList}
      />
      {show ? (
        <Spinner show={show} accessibilityLabel="spinner" />
      ) : (
        <>
          <div className="input_container mt-5">
            <h4 className="color5">上載客人需要簽署的文件</h4>
            <form onSubmit={handleUpload}>
              <div className="upload-doc-row">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    className="mt-2"
                  />
                  {uploadSuccess && (
                    <Alert
                      sx={{ width: "fit-content", marginTop: "10px" }}
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                    >
                      上載成功
                    </Alert>
                  )}
                  {error && (
                    <Alert
                      sx={{ width: "fit-content", marginTop: "10px" }}
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="error"
                    >
                      上載失敗
                    </Alert>
                  )}
                </Form.Group>
                <Button
                  type="submit"
                  variant="contained"
                  className={`${styles.list_button} mt-2`}
                  disabled={!isModifiedPdf}
                >
                  上載
                </Button>
              </div>
            </form>

            <br />
            <h2>未簽文件</h2>

            <hr className={styles.divider}></hr>
            <Box width="100%">
              <Table
                accessibilityLabel="Sticky header"
                maxHeight={500}
                // stickyColumns={1}
              >
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>文件名稱</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>上載時間</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold"></Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {docs[0]?.files
                    .filter((doc) => doc?.fileName.includes("_contract"))
                    .reverse()
                    .map((doc) => {
                      return (
                        <Table.Row key={doc?.docId}>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <a href={doc?.downloadURL} target="_blank">
                                <p>
                                  {++num}. {doc?.fileName}
                                </p>
                              </a>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <p>{doc?.timestamp}</p>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              variant="contained"
                              className={styles.list_button}
                              onClick={(e) => {
                                handleDeleteDoc(e, doc?.fileName)
                              }}
                            >
                              刪除
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          </div>
          <div className="d-flex flex-column align-items-center mt-1 mb-5">
            {/* <EditContract
              setModifiedPdf={setModifiedPdf}
              isEditable={isEditable}
            /> */}

            {/* <Button
              className={`${styles.signCanvas_button} mt-5`}
              variant="contained"
              style={{ width: "100px" }}
              onClick={handleUpload}
              disabled={isButtonDisabled}
            >
              上載PDF
            </Button> */}
            {/* {uploadSuccess && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                上載成功
              </Alert>
            )} */}
          </div>
        </>
      )}
    </div>
  )
}

export default ClientDetail
