import React, { useState, useEffect } from "react"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import { GET_USERS, UPDATE_USER, GET_USER_BY_ID } from "./UPDATE_USER_GQL"

const UpdateUser = () => {
  const { loading, error, data } = useQuery(GET_USERS)
  const [getUserById] = useLazyQuery(GET_USER_BY_ID)
  const [updateUser] = useMutation(UPDATE_USER)
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (data) {
      setUsers(data.users)
    }
  }, [data])

  const handleUpdateUser = async (id, newData) => {
    try {
      const { data } = await getUserById({ variables: { id } })
      if (!data || !data.user) {
        alert("User does not exist")
        return
      }

      const { data: updatedData } = await updateUser({
        variables: { id, data: newData },
      })
      setUsers(
        users.map((user) => (user.id === id ? updatedData.updateUser : user))
      )
    } catch (error) {
      console.error("Error updating user:", error)
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) => {
          if (message.includes("Access denied")) {
            alert(
              "Access denied: You cannot update that user - it may not exist"
            )
          } else {
            alert(`Error: ${message}`)
          }
        })
      } else {
        alert("An unexpected error occurred")
      }
    }
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error fetching users</p>

  return (
    <div>
      <h2>User List</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} ({user.email})
            <button
              onClick={() => handleUpdateUser(user.id, { name: "New Name" })}
            >
              Update
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default UpdateUser
