import { gql } from "@apollo/client"

export const GET_METAS = gql`
  query Metas {
    metas {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_META = gql`
  mutation UpdateMeta($where: MetaWhereUniqueInput!, $data: MetaUpdateInput!) {
    updateMeta(where: $where, data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const CREATE_META = gql`
  mutation CreateMeta($data: MetaCreateInput!) {
    createMeta(data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_META = gql`
  mutation DeleteMeta($where: MetaWhereUniqueInput!) {
    deleteMeta(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_METAS = gql`
  mutation DeleteMetas($where: [MetaWhereUniqueInput!]!) {
    deleteMetas(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
