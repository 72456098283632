import SignaturePad from "react-signature-canvas"
import React, { useEffect, useRef, useState } from "react"
import "./signCanvas.css"
// import { Box, Button } from "gestalt"
import Button from "@mui/material/Button"
import styles from "../../css/UI.module.css"
import SignatureCanvas from "react-signature-canvas"

const SignCanvas = ({ setTrimmedDataUrl }) => {
  //   const [trimmedDataUrl, setTrimmedDataUrl] = useState(null)
  // useEffect(() => {
  //   setTrimmedDataUrl(signRef.current.getTrimmedCanvas().toDataURL("image/png"))
  // }, [])

  const signRef = useRef()
  const clear = (e) => {
    e.preventDefault()
    signRef.current.clear()
    setTrimmedDataUrl("")
  }

  const trim = (e) => {
    e.preventDefault()

    const isMobile = window.innerWidth <= 768
    const canvas = signRef.current.getTrimmedCanvas()

    let imageUrl

    if (isMobile) {
      // 创建一个新的canvas来调整图像大小
      const mobileCanvas = document.createElement("canvas")
      const context = mobileCanvas.getContext("2d")

      // 设置新canvas的大小
      mobileCanvas.width = 150 // 设置移动设备上的宽度为150px
      mobileCanvas.height = (canvas.height * 150) / canvas.width // 按比例缩放高度

      // 在新的canvas上绘制签名图像
      context.drawImage(canvas, 0, 0, mobileCanvas.width, mobileCanvas.height)

      imageUrl = mobileCanvas.toDataURL("image/png")
    } else {
      const canvas = signRef.current.getTrimmedCanvas()
      let maxImageWidth = 300 // Set the maximum width for the desktop image
      let maxImageHeight = (canvas.height * maxImageWidth) / canvas.width // Calculate the proportional height
      //if too height, adjust the width make it fit the original ratio
      if (maxImageHeight > 200) {
        maxImageHeight = 200
        maxImageWidth = (canvas.width * maxImageHeight) / canvas.height
      }
      // Create a new canvas to resize the image
      const desktopCanvas = document.createElement("canvas")
      const context = desktopCanvas.getContext("2d")

      // Set the size of the new canvas
      desktopCanvas.width = maxImageWidth
      desktopCanvas.height = maxImageHeight

      // Draw the signature image on the new canvas
      context.drawImage(canvas, 0, 0, desktopCanvas.width, desktopCanvas.height)

      imageUrl = desktopCanvas.toDataURL("image/png")
      // imageUrl = canvas.toDataURL("image/png")
    }

    setTrimmedDataUrl(imageUrl)
  }

  return (
    <div className="signCanvas">
      <div className="sigContainer">
        <SignatureCanvas
          canvasProps={{ className: "sigPad" }}
          ref={signRef}
          clearOnResize={false}
        />

        {/* <SignaturePad canvasProps={{ className: "sigPad" }} ref={signRef} /> */}
        {/* {trimmedDataUrl ? (
          <img className="sigImage" src={trimmedDataUrl} />
        ) : null} */}
      </div>

      <div className="signCanvas-btn">
        <Button
          variant="contained"
          className={styles.signCanvas_button}
          onClick={(e) => clear(e)}
        >
          清除
        </Button>
        <Button
          variant="contained"
          className={styles.signCanvas_button}
          onClick={(e) => {
            trim(e)
          }}
        >
          檢視簽署
        </Button>
      </div>
    </div>
  )
}

export default SignCanvas
