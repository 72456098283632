import React, { useState, useRef } from "react"
import { gql, useMutation } from "@apollo/client"
import { FileUpload } from "primereact/fileupload"
import { Toast } from "primereact/toast"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"

const CREATE_POST = gql`
  mutation CreatePost($data: PostCreateInput!) {
    createPost(data: $data) {
      id
      title
      thumbnail {
        id
        url
      }
      banner {
        id
        url
      }
    }
  }
`

const UploadFile = () => {
  const [title, setTitle] = useState("")
  const [thumbnail, setThumbnail] = useState(null)
  const [banner, setBanner] = useState(null)
  const [createPost] = useMutation(CREATE_POST)
  const toast = useRef(null)

  const handleFileUpload = (event, setFile) => {
    const file = event.files[0]
    setFile(file)
  }

  const handleCreatePost = async () => {
    if (!title || !thumbnail || !banner) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please provide all required fields",
        life: 3000,
      })
      return
    }

    try {
      const { data } = await createPost({
        variables: {
          data: {
            title,
            thumbnail: { upload: thumbnail },
            banner: { upload: banner },
          },
        },
      })

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Post Created",
        life: 3000,
      })

      console.log("Created post data:", data)
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to create post",
        life: 3000,
      })
      console.error("Error creating post:", error)
    }
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="p-field">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="p-field">
        <label htmlFor="thumbnail">Thumbnail</label>
        <FileUpload
          mode="basic"
          name="thumbnail"
          auto
          customUpload
          uploadHandler={(e) => handleFileUpload(e, setThumbnail)}
          chooseLabel="Choose Thumbnail"
        />
      </div>
      <div className="p-field">
        <label htmlFor="banner">Banner</label>
        <FileUpload
          mode="basic"
          name="banner"
          auto
          customUpload
          uploadHandler={(e) => handleFileUpload(e, setBanner)}
          chooseLabel="Choose Banner"
        />
      </div>
      <Button
        label="Create Post"
        icon="pi pi-check"
        onClick={handleCreatePost}
      />
    </div>
  )
}

export default UploadFile
