import styles from "./css/loanCalculationPage.module.css"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { useEffect, useState } from "react"

const LoanCalculationPage = () => {
  const [paymentFrequency, setPaymentFrequency] = useState("half") // 預設選擇半月數
  const [loanAmount, setLoanAmount] = useState(null) // 預設貸款金額
  const [loanPeriod, setLoanPeriod] = useState(null) // 預設還款期數
  const [interestRate, setInterestRate] = useState(null) // 預設利率
  const [payment, setPayment] = useState("")

  const paymentFrequencies = [
    { label: "半月數", value: "half" },
    { label: "全月數", value: "full" },
  ]

  const calculatePayment = () => {
    if (!loanAmount || !interestRate || !loanPeriod || !paymentFrequency) return

    const principal = parseFloat(loanAmount)
    const annualRate = parseFloat(interestRate) * 0.01

    let periodRate,
      numberOfPayments = parseInt(loanPeriod)

    if (paymentFrequency === "full") {
      periodRate = annualRate / 12 // 全月數情況下的月利率
    } else if (paymentFrequency === "half") {
      periodRate = annualRate / 24 // 半月數情況下的半月利率
    }

    const payment =
      (principal * periodRate * Math.pow(1 + periodRate, numberOfPayments)) /
      (Math.pow(1 + periodRate, numberOfPayments) - 1)

    const roundedPayment = Math.round(payment) // 四捨五入至整數

    setPayment(roundedPayment)
  }

  useEffect(() => {
    calculatePayment()
  }, [loanAmount, interestRate, loanPeriod, paymentFrequency])

  return (
    <div className={styles.container}>
      <div className={styles.boxContainer}>
        <h1>貸款計算工具</h1>
        <div className={styles.gridContainer}>
          <div className={styles.item}>
            <label htmlFor="paymentFrequency">還款週期</label>
            <Dropdown
              value={paymentFrequency}
              onChange={(e) => setPaymentFrequency(e.value)}
              options={paymentFrequencies}
              optionLabel="label"
              placeholder="選擇還款週期"
            />
          </div>
          <div className={styles.item}>
            <label htmlFor="loanAmount">借貸額</label>
            <InputText
              id="loanAmount"
              value={loanAmount}
              onChange={(e) => setLoanAmount(e.target.value)}
              keyfilter="int"
            />
          </div>
          <div className={styles.item}>
            <label htmlFor="loanPeriod">還款期數</label>
            <InputText
              id="loanPeriod"
              value={loanPeriod}
              onChange={(e) => setLoanPeriod(e.target.value)}
              keyfilter="int"
            />
          </div>
          <div className={styles.item}>
            <label htmlFor="interestRate">利率 (%)</label>
            <InputText
              id="interestRate"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              keyfilter="num"
            />
          </div>
        </div>

        <div className={styles.result}>
          每期供款: ${loanPeriod && interestRate && payment ? payment : 0}
        </div>
      </div>
    </div>
  )
}

export default LoanCalculationPage
