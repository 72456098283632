import { useEffect } from "react"
import QaTabs from "../compoients/QaTabs"
import css from "./css/qa.module.css"

const QA = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={css.container}>
      <h2 style={{ fontWeight: "600", color: "white" }}>申請Q&A</h2>
      <QaTabs />
    </div>
  )
}

export default QA
