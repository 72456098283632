import { useMediaQuery } from "react-responsive"

const useDeviceQueries = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1025px)" })
  const isLaptop = useMediaQuery({
    query: "(min-width: 993px) and (max-width: 1200px)",
  })
  const isTablet = useMediaQuery({
    query: "(min-width: 601px) and (max-width: 992px)",
  })
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const isDesktop2560 = useMediaQuery({
    query: "(min-width: 1921px) and (max-width: 2560px)",
  })

  const isDesktopUpper2560 = useMediaQuery({ query: "(min-width: 2561px)" })

  const isDesktop1920 = useMediaQuery({
    query: "(min-width: 1441px) and (max-width: 1920px)",
  })
  const isDesktop1440 = useMediaQuery({
    query: "(min-width: 1025px) and (max-width: 1440px)",
  })

  //detect width and height at same time 1920 * 1080
  const is1920_1080 = useMediaQuery({
    query: "(min-width: 1921px) and (min-height: 1081px)",
  })
  //1280 × 1024
  const is1280_1024 = useMediaQuery({
    query: "(min-width: 1280px) and (min-height: 1024px)",
  })

  //1024 × 768
  const is1024_768 = useMediaQuery({
    query: "(min-width: 1024px) and (min-height: 768px)",
  })

  const is1440_900 = useMediaQuery({
    query: "(min-width: 1441px) and (min-height: 901px)",
  })

  //detect width and height at same time 2560 * 1440
  const is2560_1440 = useMediaQuery({
    query: "(min-width: 2561px) and (min-height: 1441px)",
  })

  //detect width and height at same time 2560 * 1080
  const is2560_1080 = useMediaQuery({
    query: "(min-width: 2560px) and (min-height: 1080px)",
  })

  return {
    isDesktop,
    isTablet,
    isMobile,
    isLaptop,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
    isDesktopUpper2560,
    is1024_768,
    is1280_1024,
    is1440_900,
    is1920_1080,
    is2560_1440,
    is2560_1080,
  }
}

export default useDeviceQueries
