import React, { useState } from "react"
import styles from "./css/blog.module.css"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import { ListItemButton } from "@mui/material"
import banner from "../images/blog/banner.png"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { InputText } from "primereact/inputtext"
import searchIcon from "../images/blog/search_icon.png"
import { useNavigate } from "react-router-dom"
import { Paths } from "../utils/Paths"
import { useQuery } from "@apollo/client"
import { GET_POSTS } from "../graphql/Post/POST_GQL"
// import { DocumentRenderer } from "@keystone-6/document-renderer"
import useDeviceQueries from "../hooks/useDeviceQueries"

const categories = ["全部文章", "理財Tips", "貸款諮詢"]

export default function Blog() {
  const {
    isDesktop,
    isTablet,
    isMobile,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  } = useDeviceQueries()
  const { loading, error, data } = useQuery(GET_POSTS)

  const [searchKeyword, setSearchKeyword] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("全部文章")
  const [page, setPage] = useState(1)
  const navigate = useNavigate()

  const filteredPosts = data?.posts?.filter(
    (post) =>
      (selectedCategory === "全部文章" ||
        post.tags[0].name.toLowerCase() === selectedCategory.toLowerCase()) &&
      (post.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        post.content.toLowerCase().includes(searchKeyword.toLowerCase()))
  )

  const postsPerPage = 6
  const displayedPosts = (filteredPosts || []).slice(
    (page - 1) * postsPerPage,
    page * postsPerPage
  )

  // if (loading) return <p>Loading...</p>
  // if (error) return <p>Error: {error.message}</p>

  return (
    <div className="loanProduct-container">
      <div
        className="normal-banner"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <h1 style={{ color: "#10463e", fontWeight: "600" }}>信貸您要知</h1>
      </div>
      <div className={styles.searchWrapper}>
        <IconField
          iconPosition="left"
          className={styles.search_container}
          style={{ width: "35%", maxWidth: "700px", minWidth: "250px" }}
        >
          {/* <InputIcon>
                    <i className="pi pi-search" style={{ verticalAlign: "top" }} />
                </InputIcon> */}
          <InputIcon>
            <img src={searchIcon} alt="search_icon" />
          </InputIcon>
          <InputText
            type="search"
            value={searchKeyword}
            onInput={(e) => setSearchKeyword(e.target.value)}
            placeholder="請輸入關鍵詞查找文章"
          />
        </IconField>
      </div>
      <Box className={styles.container}>
        <Box className={styles.filterContainer}>
          <List component="nav" className={styles.filterList}>
            {categories.map((category, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  className={styles.filterItem}
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                >
                  <ListItemText primary={category} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Grid container spacing={2} className={styles.postsContainer}>
            {displayedPosts?.map((post) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                key={post.id}
                className={styles.postCard}
              >
                <div
                  onClick={() => {
                    navigate(`${Paths.blog}/${post.id}`)
                  }}
                >
                  <Card className={styles.post}>
                    <CardMedia
                      component="img"
                      height="180"
                      image={post?.banner?.url}
                      alt={post?.title}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        className="color4"
                        sx={{ fontWeight: "600" }}
                      >
                        {post?.tags[0]?.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ fontWeight: "600" }}
                      >
                        {post?.title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {/* <DocumentRenderer document={post.content.document} /> */}
                        {post?.content.split(" ").slice(0, 30).join(" ")}...
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Pagination
          count={Math.ceil(filteredPosts?.length / postsPerPage)}
          page={page}
          onChange={(event, value) => {
            setPage(value)
            window.scrollTo({ top: 0, behavior: "instant" })
          }}
          className={styles.paginationContainer}
        />
        <div className="space"></div>
      </Box>
    </div>
  )
}
