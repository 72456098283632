import css from "./css/homePart2Box.module.css"
import ImageVerticalText from "./ImageVerticalText"
import icon1 from "../images/homePart2Box/part2-piont1.png"
import icon2 from "../images/homePart2Box/part2-piont2.png"
import icon3 from "../images/homePart2Box/part2-piont3.png"
import icon4 from "../images/homePart2Box/part2-piont4.png"

import { Carousel } from "primereact/carousel"
import { useEffect, useState } from "react"
import useDeviceQueries from "../hooks/useDeviceQueries"

const HomePart2Box = () => {
  const { isDesktop, isTablet, isMobile } = useDeviceQueries()
  const [carouselItems, setCarouselItems] = useState([])

  useEffect(() => {
    setCarouselItems([
      {
        image: icon1,
        title: "一對一跟進",
        text: "專人分析財務狀況重建個人信貸記錄",
      },
      {
        image: icon2,
        title: "MIN PAY殺手",
        text: "每月MIN PAY即時還少一半，避免出現入不敷支付情況",
      },
      {
        image: icon3,
        title: "輕鬆供款",
        text: "因應個人供款能力不同，我們貼心計算還款年期，從而令客戶輕鬆供款",
      },
      {
        image: icon4,
        title: "免查TU",
        text: "免查信貸記錄即可申請",
      },
    ])
  }, [])

  const content = [
    {
      image: icon1,
      title: "一對一跟進",
      text: "專人分析財務狀況重建個人信貸記錄",
    },
    {
      image: icon2,
      title: "MIN PAY殺手",
      text: "每月MIN PAY即時還少一半，避免出現入不敷支付情況",
    },
    {
      image: icon3,
      title: "輕鬆供款",
      text: "因應個人供款能力不同，我們貼心計算還款年期，從而令客戶輕鬆供款",
    },
    {
      image: icon4,
      title: "免查TU",
      text: "免查信貸記錄即可申請",
    },
  ]

  const productTemplate = (item) => {
    return (
      <div className={css.carouselItem}>
        <img src={item.image} alt={item.title} className={css.carouselImage} />
        <h3 className={css.carouselTitle}>{item.title}</h3>
        <p className={css.carouselText}>{item.text}</p>
      </div>
    )
  }

  const responsiveOptions = [
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1,
    },
  ]

  if (isMobile || isTablet) {
    return (
      <div className={css.container_mob}>
        <Carousel
          className={css.carousel_style}
          value={carouselItems}
          numVisible={1}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
          circular
          autoplayInterval={3000}
        />
      </div>
    )
  } else
    return (
      <div className={css.container}>
        <div className={css.wrapper}>
          {content.map((item, index) => (
            <ImageVerticalText key={index} item={item} />
          ))}
        </div>
      </div>
    )
}

export default HomePart2Box
