import { gql } from "@apollo/client"

export const GET_ABOUTS = gql`
  query Abouts {
    abouts {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_ABOUT = gql`
  mutation UpdateAbout(
    $where: AboutWhereUniqueInput!
    $data: AboutUpdateInput!
  ) {
    updateAbout(where: $where, data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const CREATE_ABOUT = gql`
  mutation CreateAbout($data: AboutCreateInput!) {
    createAbout(data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const DELETE_ABOUT = gql`
  mutation DeleteAbout($where: AboutWhereUniqueInput!) {
    deleteAbout(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const DELETE_ABOUTS = gql`
  mutation DeleteAbouts($where: [AboutWhereUniqueInput!]!) {
    deleteAbouts(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
