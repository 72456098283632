import { gql } from "@apollo/client"

export const GET_POSTS = gql`
  query Posts {
    posts {
      id
      title
      thumbnail {
        id
        url
      }
      banner {
        id
        url
      }
      content
      order
      author {
        id
        name
      }
      tags {
        id
        name
      }
      tagsCount
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_POST = gql`
  mutation UpdatePost($where: PostWhereUniqueInput!, $data: PostUpdateInput!) {
    updatePost(where: $where, data: $data) {
      id
      title
      thumbnail {
        id
        url
      }
      banner {
        id
        url
      }
      content
      order
      author {
        id
        name
      }
      tags {
        id
        name
      }
      tagsCount
      createdAt
      updatedAt
    }
  }
`
export const CREATE_POST = gql`
  mutation CreatePost($data: PostCreateInput!) {
    createPost(data: $data) {
      id
      title
      thumbnail {
        id
        url
      }
      banner {
        id
        url
      }
      content
      order
      author {
        id
        name
      }
      tags {
        id
        name
      }
      tagsCount
      createdAt
      updatedAt
    }
  }
`

export const DELETE_POST = gql`
  mutation DeletePost($where: PostWhereUniqueInput!) {
    deletePost(where: $where) {
      id
      title
      thumbnail {
        url
      }
      banner {
        url
      }
      content
      order
      author {
        name
      }
      tags {
        name
      }
      tagsCount
      createdAt
      updatedAt
    }
  }
`
export const DELETE_POSTS = gql`
  mutation DeletePosts($where: [PostWhereUniqueInput!]!) {
    deletePosts(where: $where) {
      id
      title
      thumbnail {
        url
      }
      banner {
        url
      }
      content
      order
      author {
        name
      }
      tags {
        name
      }
      tagsCount
      createdAt
      updatedAt
    }
  }
`
