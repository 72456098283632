import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router"
import "./userDashboard.css"
import { useEffect, useState } from "react"
import {
  // uploadFile,
  uploadSupportingFile,
  listenUser,
  listenSignedDocs,
  listenUnsignDocs,
} from "../../firebaseConf"
import { Table, Text, Spinner, Box } from "gestalt"
import "gestalt/dist/gestalt.css"
import { Form, FormControl, InputGroup } from "react-bootstrap"
import { useRef } from "react"
import { approvalStatusOptions } from "../../keyvalue/approvalStatusOptions"
import { Paths } from "../../../utils/Paths"

const UserDashboard = () => {
  let navigate = useNavigate()

  let { id } = useParams()

  let num = 0
  let signed_num = 0

  const [docs, setDocs] = useState([])
  const [signedDocs, setSignedDocs] = useState([])
  const [show, setShow] = useState(true)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState("")
  const [idCard, setIdCard] = useState(id)

  const fileUploadRef = useRef(null)
  useEffect(() => {
    const unsubscribeUser = listenUser(idCard, (userData) => {
      if (userData) {
        setApprovalStatus(
          approvalStatusOptions[userData.approvalStatus] || "Invalid Status"
        )
      }
    })

    return () => unsubscribeUser()
  }, [idCard])

  useEffect(() => {
    const unsubscribeUnsignDocs = listenUnsignDocs(idCard, (docs) => {
      setDocs(docs)
      setShow(false)
    })

    const unsubscribeSignedDocs = listenSignedDocs(idCard, (signedDocs) => {
      setSignedDocs(signedDocs)
      setShow(false)
    })

    return () => {
      unsubscribeUnsignDocs()
      unsubscribeSignedDocs()
    }
  }, [idCard])

  const handleUpload = (e) => {
    e.preventDefault()
    const fileInput = fileUploadRef.current
    if (fileInput && fileInput.files.length === 0) {
      alert("請選擇上載文件")
      return
    }
    const file = fileInput.files[0]
    const uploadDocs = async () => {
      const rs = await uploadSupportingFile(file, id)
      if (rs === "success") {
        setUploadSuccess(true)
        setError(false)
        fileInput.value = ""
      } else {
        setError(true)
        setUploadSuccess(false)
      }
      setShow(false)
    }

    uploadDocs()
  }
  return (
    <div className={styles.container}>
      <Profile back_url={""} logoLink={Paths.userDashboard + id} />
      {show ? (
        <Spinner show={show} accessibilityLabel="spinner" />
      ) : (
        <div className="input_container">
          <br />
          <div>
            <form onSubmit={handleUpload}>
              <div className="upload-doc-row">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" ref={fileUploadRef} />
                  {uploadSuccess && <Form.Label>上載完成</Form.Label>}
                  {error && <Form.Label>上載失敗</Form.Label>}
                  <br />
                </Form.Group>
                <Button
                  type="submit"
                  variant="contained"
                  className={`${styles.upload_button} ms-2`}
                  disabled={show}
                >
                  上載
                </Button>
              </div>
            </form>
          </div>

          <div className={styles.approvalStatus}>
            <label>批核狀態：</label>
            <span>{approvalStatus}</span>
          </div>
          {/* Supporting File */}
          <div className="user-control-row">
            <h2>已上載文件</h2>
          </div>
          <hr className={styles.divider}></hr>
          {docs[0] ? (
            <Box width="100%">
              <Table
                accessibilityLabel="Sticky header"
                maxHeight={500}
                stickyColumns={1}
              >
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>文件名稱</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>更新時間</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold"></Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {docs[0]?.files
                    .filter((doc) => !doc?.fileName.includes("_contract"))
                    .reverse()
                    .map((doc, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <a href={doc?.downloadURL} target="_blank">
                                <p>
                                  {++num}. {doc?.fileName}
                                </p>
                              </a>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{doc?.timestamp}</p>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          ) : (
            <h3>沒有文件</h3>
          )}

          {/* 未簽文件 */}

          <div className="user-control-row">
            <h2>未簽文件</h2>
          </div>
          <hr className={styles.divider}></hr>
          {docs[0] ? (
            <Box width="100%">
              <Table
                accessibilityLabel="Sticky header"
                maxHeight={500}
                // stickyColumns={1}
              >
                <Table.Header sticky>
                  <Table.Row>
                    {/* <Table.HeaderCell>
                      <Text weight="bold"></Text>
                    </Table.HeaderCell> */}
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>更新時間</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>文件名稱</div>
                      </Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {docs[0]?.files
                    .filter((doc) => doc?.fileName.includes("_contract"))
                    .reverse()
                    .map((doc, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <p>{doc?.timestamp}</p>
                            </div>
                            <Button
                              variant="contained"
                              className={styles.sign_button}
                              onClick={() => {
                                navigate(
                                  Paths.userDocSign + doc?.fileName + "/" + id
                                )
                              }}
                            >
                              簽署
                            </Button>
                          </Table.Cell>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              {/* <a href={doc?.downloadURL} target="_blank"> */}
                              <p>
                                {++num}. {doc?.fileName}
                              </p>
                              {/* </a> */}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          ) : (
            <h3>沒有文件</h3>
          )}
          {/* <div className="user-control-row">
            <h2>已簽文件</h2>
          </div>

          <hr className={styles.divider}></hr>

          {signedDocs[0] ? (
            <Box width="100%">
              <Table accessibilityLabel="Sticky header" maxHeight={500}>
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>文件名稱</div>
                      </Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">
                        <div style={{ whiteSpace: "nowrap" }}>更新時間</div>
                      </Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {signedDocs[0]?.files
                    .filter((doc) => doc?.fileName.includes("_contract"))
                    .reverse()
                    .map((doc, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <a href={doc?.downloadURL} target="_blank">
                                <p>
                                  {++signed_num}. {doc?.fileName}
                                </p>
                              </a>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <p>{doc?.timestamp}</p>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          ) : (
            <h3>沒有文件</h3>
          )} */}
        </div>
      )}
    </div>
  )
}

export default UserDashboard
