import { gql } from "@apollo/client"

export const GET_APPLICATION_FORMS_ALL = gql`
  query ApplicationForms {
    applicationForms {
      id
      referenceNo
      name
      phone
      amount
      paymentPeriod
      paymentMethod
      comname
      salary
      idNumber
      finished
      createdAt
      updatedAt
    }
  }
`

export const GET_APPLICATION_FORMS = gql`
  query ApplicationForms {
    applicationForms(where: { finished: { equals: true } }) {
      id
      referenceNo
      name
      phone
      amount
      paymentPeriod
      paymentMethod
      comname
      salary
      idNumber
      finished
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_APPLICATION_FORM = gql`
  mutation UpdateApplicationForm(
    $where: ApplicationFormWhereUniqueInput!
    $data: ApplicationFormUpdateInput!
  ) {
    updateApplicationForm(where: $where, data: $data) {
      id
      referenceNo
      name
      phone
      amount
      paymentPeriod
      paymentMethod
      comname
      salary
      idNumber
      finished
      createdAt
      updatedAt
    }
  }
`

export const CREATE_APPLICATION_FORM = gql`
  mutation CreateApplicationForm($data: ApplicationFormCreateInput!) {
    createApplicationForm(data: $data) {
      id
      referenceNo
      name
      phone
      amount
      paymentPeriod
      paymentMethod
      comname
      salary
      idNumber
      finished
      createdAt
      updatedAt
    }
  }
`

export const DELETE_APPLICATION_FORM = gql`
  mutation DeleteApplicationForm($where: ApplicationFormWhereUniqueInput!) {
    deleteApplicationForm(where: $where) {
      id
      referenceNo
      name
      phone
      amount
      paymentPeriod
      paymentMethod
      comname
      salary
      idNumber
      finished
      createdAt
      updatedAt
    }
  }
`
export const DELETE_APPLICATION_FORMS = gql`
  mutation DeleteApplicationForms($where: [ApplicationFormWhereUniqueInput!]!) {
    deleteApplicationForms(where: $where) {
      id
      referenceNo
      name
      phone
      amount
      paymentPeriod
      paymentMethod
      comname
      salary
      idNumber
      finished
      createdAt
      updatedAt
    }
  }
`
