import React from "react"
import { useMediaQuery } from "react-responsive"
import { Carousel } from "primereact/carousel"
import "./css/featurePaperSection.css"
import { FormDatatext } from "../hooks/useFormatDatatext"

const FeaturePaperSection = ({ data }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "800px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ]

  const itemTemplate = (item) => {
    return (
      <div className="feature-paper-item-mobile">
        <h3>{item.title}</h3>
        <img src={item.icon} alt={item.title} />
        <p>{FormDatatext(item.desc)}</p>
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className="feature-paper-carousel">
        <Carousel
          value={data}
          numScroll={1}
          numVisible={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={itemTemplate}
          circular={true}
          autoplayInterval={3000}
        />
      </div>
    )
  } else {
    return (
      <div className="feature-paper-container">
        <div className="feature-paper-items-wrapper">
          {data.map((item, index) => (
            <div className="feature-paper-item" key={index}>
              <h3>{item.title}</h3>
              <img src={item.icon} alt={item.title} />
              <p>{FormDatatext(item.desc)}</p>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default FeaturePaperSection
