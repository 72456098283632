import { styled } from "@mui/material/styles"
import { TextField } from "@mui/material"
export const MuiTextfield = styled(TextField)({
  "& label": {
    color: "#108d91",
  },
  "& label.Mui-focused": {
    color: "#108d91",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#108d91",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      boxShadow: "0 0 4px 3px #108d912e",
    },
    // "& fieldset": {
    //   borderColor: "#108d9159",
    //   boxShadow: "0 0 4px 3px #108d912e",
    // },
    // "&:hover fieldset": {
    //   borderColor: "#108d9159",
    // },
    // "&.Mui-focused fieldset": {
    //   borderColor: "#108d9159",
    //   //   boxShadow: "unset ",
    // },
  },
})
