import { gql } from "@apollo/client"

export const GET_QAS = gql`
  query QAS {
    qAS {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_QA = gql`
  mutation UpdateQA($where: QAWhereUniqueInput!, $data: QAUpdateInput!) {
    updateQA(where: $where, data: $data) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`
export const CREATE_QA = gql`
  mutation CreateQA($data: QACreateInput!) {
    createQA(data: $data) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`
export const DELETE_QA = gql`
  mutation DeleteQA($where: QAWhereUniqueInput!) {
    deleteQA(where: $where) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`
export const DELETE_QAS = gql`
  mutation DeleteQAS($where: [QAWhereUniqueInput!]!) {
    deleteQAS(where: $where) {
      id
      question
      answer
      order
      createdAt
      updatedAt
    }
  }
`
