import { useQuery } from "@apollo/client"
import { findContentByLabel } from "../utils/findContentByLabel"
import { wrapText } from "../utils/wrapText"
import CalculatorStyle2 from "./CalculatorStyle2"
import css from "./css/separatedSection.module.css"
import ImageWithText from "./ImageWithText"
import { GET_HOMES } from "../graphql/Home/HOME_GQL"
import { useEffect } from "react"

const SeparatedSection = () => {
  const { loading, data } = useQuery(GET_HOMES)

  const title =
    findContentByLabel(data?.homes, "section1_slogan_title") ||
    "極速放款解決您的燃眉之急"
  const description =
    findContentByLabel(data?.homes, "section1_slogan_desc") ||
    "網上智能貸款 AI 數據批核，免入息證明、免手續費，為您即時解決資金周轉，輕鬆應你所急！"
  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <div className={css.leftSection}>
          <ImageWithText />
          <div className={css.slogan}>
            <h1 style={{ fontWeight: "700" }}>{title}</h1>
            <h6 style={{ fontWeight: "600" }}>{wrapText(description)}</h6>
          </div>
        </div>
        <div className={css.rightSection}>
          <CalculatorStyle2 />
        </div>
      </div>
    </div>
  )
}

export default SeparatedSection
