import { wrapText } from "../utils/wrapText"
import css from "./css/imageVerticalText.module.css"

const ImageVerticalText = ({ item }) => {
  return (
    <div className={css.container}>
      <div className={css.item}>
        <div className={css.image}>
          <img src={item.image} alt="icon" />
        </div>
        <div className={css.title}>{item.title}</div>
        <div className={css.text}>{wrapText(item.text)}</div>
      </div>
    </div>
  )
}

export default ImageVerticalText
