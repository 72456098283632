import { useNavigate } from "react-router-dom"
import HomePart2Box from "../compoients/HomePart2Box"
import StepInfoTab from "../compoients/StepInfoTab"
import { wrapText } from "../utils/wrapText"
import css from "./css/personalLoan.module.css"
import { Paths } from "../utils/Paths"
import QaTabs from "../compoients/QaTabs"
import { GET_ABOUTS } from "../graphql/About/ABOUT_GQL"
import { useQuery } from "@apollo/client"
import { findContentByLabel } from "../utils/findContentByLabel"

const AboutUs = () => {
  const { loading, error, data } = useQuery(GET_ABOUTS)
  const bannerDesc = findContentByLabel(data?.abouts, "bannerDesc")
  const navigate = useNavigate()
  const bannerTitle = "關於我們"
  // const bannerDesc = `【CREDITPRO】致力為客戶提供貼身靈活的私人貸款，毋須查閱客戶的信貸評級報告(TU)，客戶更不用擔憂申請貸款會影響其信貸評級，以方便客戶節省更多時間，令週轉更靈活快捷。同時為滿足不同客戶的需要，【CREDIT PRO】提供多項貸款計劃，及多項申請貸款方法，批核快捷，即日安排提取貸款。`

  return (
    <div className={css.container}>
      <div className={css.banner_wrapper}>
        <div className={css.banner_content}>
          <h4>{bannerTitle}</h4>
          <p>{wrapText(bannerDesc)}</p>
        </div>
      </div>
      <div className={css.page_content}>
        <div className={css.section1}>
          <h5>極速處理，即批即過戶</h5>
          <h2 style={{ fontWeight: "700" }}>
            全自動簡化貸款過程貸款申請無間斷
          </h2>
          <h5>為你提供最合理合適報價</h5>
          <HomePart2Box />
          <div style={{ marginTop: "5rem", marginBottom: "4rem" }}>
            <StepInfoTab />
          </div>
          <button
            className={`applyBtn-lg animatedBtn`}
            onClick={() => navigate(Paths.userLogin)}
          >
            立即申請
          </button>
        </div>
        <div className="d-flex flex-column align-items-center mb-5 mt-5">
          <h2 style={{ fontWeight: "600", color: "white" }}>申請Q&A</h2>
          <QaTabs />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
