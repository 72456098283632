import { gql } from "@apollo/client"

export const GET_HOMES = gql`
  query Homes {
    homes {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_HOME = gql`
  mutation UpdateHome($where: HomeWhereUniqueInput!, $data: HomeUpdateInput!) {
    updateHome(where: $where, data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const CREATE_HOME = gql`
  mutation CreateHome($data: HomeCreateInput!) {
    createHome(data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const DELETE_HOME = gql`
  mutation DeleteHome($where: HomeWhereUniqueInput!) {
    deleteHome(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_HOMES = gql`
  mutation DeleteHomes($where: [HomeWhereUniqueInput!]!) {
    deleteHomes(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
