import React from "react"
import textboxRight from "../../images/featureSection/textbox_right.png"
import textboxLeft from "../../images/featureSection/textbox_left.png"
import pointBg from "../../images/featureSection/pointBg.png"
import css from "./featuresSection.module.css"
import useDeviceQueries from "../../hooks/useDeviceQueries"

const FeatureBox = ({ imgSrc, imgAlt, title, description }) => (
  <div className={css.featureBox}>
    <img src={imgSrc} alt={imgAlt} className={css.featureImage} />
    <div className={css.featureContent}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
)

const FeatureBox_mob = ({
  imgSrc,
  imgAlt,
  title,
  description,
  alignment,
  leftPosition,
}) => (
  <div
    className={`${css.featureBox} ${
      alignment === "right" ? css.alignRight : css.alignLeft
    }`}
  >
    <img src={imgSrc} alt={imgAlt} className={css.featureImage} />
    <div className={css.featureContent} style={{ left: leftPosition }}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
)

const FeaturesSection = () => {
  const { isDesktop, isTablet, isMobile } = useDeviceQueries()

  const leftFeatures = [
    {
      imgSrc: textboxLeft,
      imgAlt: "textbox left",
      title: "A.I.級輕便",
      description: "網上貸款簡化申請",
    },
    {
      imgSrc: textboxLeft,
      imgAlt: "textbox left",
      title: "A.I.級速度",
      description: "網上貸款即時處理",
    },
    {
      imgSrc: textboxLeft,
      imgAlt: "textbox left",
      title: "A.I.級貸款無需露面",
      description: "申請至簽約過數全程網上辦妥",
    },
  ]

  const rightFeatures = [
    {
      imgSrc: textboxRight,
      imgAlt: "textbox right",
      title: "打破傳統限制",
      description: "網上貸款簡化申請",
    },
    {
      imgSrc: textboxRight,
      imgAlt: "textbox right",
      title: "申請至過數 最快15分鐘",
      description: "即時滿足您的緊急周轉需要",
    },
    {
      imgSrc: textboxRight,
      imgAlt: "textbox right",
      title: "網上貸零露面 零尷尬",
      description: "24小時網上服務 不受時間地區限制",
    },
  ]

  const orderedFeatures_mob = [
    {
      imgSrc: textboxLeft,
      imgAlt: "textbox left",
      title: "A.I.級輕便",
      description: "網上貸款簡化申請",
    },
    {
      imgSrc: textboxRight,
      imgAlt: "textbox right",
      title: "打破傳統限制",
      description: "網上貸款簡化申請",
    },
    {
      imgSrc: textboxLeft,
      imgAlt: "textbox left",
      title: "A.I.級速度",
      description: "網上貸款即時處理",
    },
    {
      imgSrc: textboxRight,
      imgAlt: "textbox right",
      title: "申請至過數 最快15分鐘",
      description: "即時滿足您的緊急周轉需要",
    },
    {
      imgSrc: textboxLeft,
      imgAlt: "textbox left",
      title: "A.I.級貸款無需露面",
      description: "申請至簽約過數全程網上辦妥",
    },
    {
      imgSrc: textboxRight,
      imgAlt: "textbox right",
      title: "網上貸零露面 零尷尬",
      description: "24小時網上服務 不受時間地區限制",
    },
  ]

  if (isMobile || isTablet) {
    return (
      <div className={css.featuresSection_mob}>
        <div className={css.centerSection}>
          <img
            src={pointBg}
            alt="Center Graphic"
            className={css.centerImage_mob}
          />
          <div className={css.centerContent_mob}>
            <h3>24小時</h3>
            <h3>網上自助申請</h3>
          </div>
        </div>

        {orderedFeatures_mob.map((feature, index) => (
          <FeatureBox_mob
            key={index}
            {...feature}
            alignment={index % 2 === 0 ? "right" : "left"}
            leftPosition={index % 2 === 0 ? "46%" : "55%"} // Alternating left position
          />
        ))}
      </div>
    )
  } else {
    return (
      <div className={css.featuresSection}>
        <div className={css.leftSection}>
          {leftFeatures.map((feature, index) => (
            <FeatureBox key={index} {...feature} />
          ))}
        </div>

        <div className={css.centerSection}>
          <img src={pointBg} alt="Center Graphic" className={css.centerImage} />
          <div className={css.centerContent}>
            <h3>24小時</h3>
            <h3>網上自助申請</h3>
          </div>
        </div>

        <div className={css.rightSection}>
          {rightFeatures.map((feature, index) => (
            <FeatureBox key={index} {...feature} />
          ))}
        </div>
      </div>
    )
  }
}

export default FeaturesSection
