import { ApolloClient, InMemoryCache } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { setContext } from "@apollo/client/link/context"
import Cookies from "js-cookie"

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT, // 替換為你的 Keystone GraphQL API 端點
})

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("keystonejs-session") // 從 cookies 獲取 token

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "x-apollo-operation-name": "FileUpload", //CSRF（跨站請求偽造）保護
      // 或者使用 'apollo-require-preflight'
      // "apollo-require-preflight": "true",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
})

export default client
