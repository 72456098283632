import { wrapText } from "../utils/wrapText"
import css from "./css/stepInfoTab.module.css"
import step1 from "../images/stepInfo/flowpathbox1.png"
import step2 from "../images/stepInfo/flowpathbox2.png"
import step3 from "../images/stepInfo/flowpathbox3.png"

const StepInfoTab = () => {
  const title = "Online申請 無需現身\n全程網上搞掂"
  const steps = [
    { number: "1", text: "Online登記", image: step1 },
    { number: "2", text: "Online簽署", image: step2 },
    { number: "3", text: "Online過數", image: step3 },
  ]

  return (
    <div className={css.container}>
      <h3>{wrapText(title)}</h3>
      <div className={css.stepsContainer}>
        {steps.map((step, index) => (
          <div key={index} className={css.step}>
            <div className={css.stepNumber}>{step.number}</div>
            <div
              className={css.stepText}
              style={{ backgroundImage: `url(${step.image})` }}
            >
              {step.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepInfoTab
