import { gql } from "@apollo/client"

export const GET_PERSONAL_LOANS = gql`
  query PersonalLoans {
    personalLoans {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_PERSONALLOAN = gql`
  mutation UpdatePersonalLoan(
    $where: PersonalLoanWhereUniqueInput!
    $data: PersonalLoanUpdateInput!
  ) {
    updatePersonalLoan(where: $where, data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const CREATE_PERSONALLOAN = gql`
  mutation CreatePersonalLoan($data: PersonalLoanCreateInput!) {
    createPersonalLoan(data: $data) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_PERSONALLOAN = gql`
  mutation DeletePersonalLoan($where: PersonalLoanWhereUniqueInput!) {
    deletePersonalLoan(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
export const DELETE_PERSONALLOANS = gql`
  mutation DeletePersonalLoans($where: [PersonalLoanWhereUniqueInput!]!) {
    deletePersonalLoans(where: $where) {
      id
      label
      content
      createdAt
      updatedAt
    }
  }
`
