import moment from "moment"
export const checkUserVaild = (input) => {
  let userTimestamp = input.timestamp
  userTimestamp = moment(userTimestamp, "MMMM Do YYYY, h:mm:ss a")
  let now_date = moment()
  let date_diff = now_date.diff(userTimestamp, "days")
  if (Math.abs(date_diff) >= 1) {
    return false
  } else {
    return true
  }
}
