import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import { useNavigate, useParams } from "react-router"
import { Form } from "react-bootstrap"

import { updateUserPassword } from "../../firebaseConf"
import { useEffect, useState } from "react"
import { Table, Text, Spinner } from "gestalt"
import "gestalt/dist/gestalt.css"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { Paths } from "../../../utils/Paths"

const ChangePassowrd = () => {
  const [error, setError] = useState("")
  const [msg, setMsg] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  let { id } = useParams()
  const handleSubmit = (e) => {
    e.preventDefault()
    setError("")
    setMsg("")

    async function changePassword() {
      if (newPassword === confirmPassword) {
        const rs = await updateUserPassword(id, newPassword)
        if (rs.result === "success") {
          setMsg("更改密碼成功")
        } else {
          setError("更改密碼失敗")
        }
      } else {
        setError("請填寫一致的密碼以作確認")
      }
    }

    setTimeout(() => {
      changePassword()
    }, 1000)
  }

  return (
    <div className={styles.container}>
      <Profile
        back_url={Paths.esignClientList}
        logoLink={Paths.esignDashboard}
      />
      <div className="input_container">
        <br />
        <br />
        <h2>更改密碼(電話號碼)</h2>
        <hr className={styles.divider}></hr>
        <TextField
          id="idCard"
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="請輸入新密碼"
          label="新密碼"
          value={newPassword}
          type="text"
          className={styles.input_field}
        />
        <TextField
          id="idCard"
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="請重新輸入一次新密碼以作確認"
          label="確認新密碼"
          value={confirmPassword}
          type="text"
          className={styles.input_field}
        />
        <Button
          variant="contained"
          className={styles.functional_button}
          onClick={(e) => {
            handleSubmit(e)
          }}
        >
          更改
        </Button>
        <h4>{msg}</h4>
        <h4>{error}</h4>
      </div>
    </div>
  )
}

export default ChangePassowrd
