import { gql } from "@apollo/client"

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      name
      email
      isAdmin
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $data: UserUpdateInput!) {
    updateUser(where: { id: $id }, data: $data) {
      id
      name
      email
      isAdmin
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    user(where: { id: $id }) {
      id
      name
      email
      isAdmin
    }
  }
`
