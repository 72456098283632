import { Helmet } from "react-helmet"

export function MetaPixel({ MetaPixelScript, MetaPixelNoScript }) {
  return (
    <Helmet>
      <script>{MetaPixelScript}</script>
      <noscript>{MetaPixelNoScript}</noscript>
    </Helmet>
  )
}
